import { Select, SelectProps } from 'antd';
import clsx from 'clsx';
import React, { FC, KeyboardEvent, useCallback } from 'react';
import { EMAIL_REGEXP } from 'validators/email';

import styles from './EmailsField.module.less';

interface Props extends SelectProps<string[]> {
  disabled?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
  onInputChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  stringType?: boolean;
}

const tagClassName = 'ant-select-selection-item-content';
const separators = [',', ';', ' '];
const separatorPattern = new RegExp(`[${separators.join('')}]`, 'g');
const clearInput = (value = '') => value.replace(separatorPattern, '');

export const EmailsField: FC<Props> = (props) => {
  const { className, placeholder, value, disabled, onChange, onInputChange, ...rest } = props;
  const [inputValue, setInputValue] = React.useState<string>('');

  const handleInputChange = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement;

      // Confirm selection by removing and adding blur
      if (separators.includes(e.key)) {
        input.blur();

        requestAnimationFrame(() => {
          input.focus();
        });
      }

      const clearedValue = clearInput(input.value);

      onInputChange?.(clearedValue);
    },
    [onInputChange],
  );

  const handleChange = useCallback(
    (values: string[]) => {
      setInputValue('');

      if (!onChange) return;

      const clearedValues = values.map(clearInput);
      const correctValues = clearedValues.filter((item) => EMAIL_REGEXP.test(item));

      onChange(correctValues);
    },
    [onChange],
  );

  // Click on tag to edit it.
  const handleClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      const targetClassName = (event.target as HTMLElement)?.className;
      const targetText = (event.target as HTMLElement)?.textContent;

      if (targetClassName === tagClassName && targetText) {
        const restEmails = value?.filter((email) => email !== targetText) || [];
        onChange?.(restEmails);
        setInputValue(targetText);
        onInputChange?.(targetText);
      }
    },
    [onChange, onInputChange, value],
  );

  return (
    <div className={clsx(styles.wrapper, className)}>
      <Select
        disabled={disabled}
        placeholder={placeholder}
        mode="tags"
        value={value}
        className={styles.select}
        popupClassName={styles.dropdown}
        onChange={handleChange}
        onSearch={setInputValue}
        searchValue={inputValue}
        onKeyUp={handleInputChange}
        onClick={handleClick}
        {...rest}
      />
    </div>
  );
};
