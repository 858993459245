import { getAccountingIdName } from 'config/regions/utils';
import { ENV_BASE_URL } from 'constants/env';

const root = () => ENV_BASE_URL || '/';

const lists = `${root()}lists`;

const financeRootPath = () => `${lists}/finance`;
const outcomingInvoicesPath = () => `${financeRootPath()}/outcoming_invoices`;
const oldPartnerInvoicesPath = () => `${financeRootPath()}/partner_invoices`;
const newPartnerInvoicesPath = () => `${financeRootPath()}/partner_invoices/new`;

const lookers = {
  crm: {
    rootPath: () => `${lists}/crm`,
    deals: {
      today: () => `${lists}/crm/deals/today`,
      last7Days: () => `${lists}/crm/deals/last_7_days_deals`,
      todayOrders: () => `${lists}/crm/deals/today_orders`,
      last7DaysOrders: () => `${lists}/crm/deals/last_7_days_orders`,
      won2021: () => `${lists}/crm/deals/won_2021`,
    },
    orgsPath: () => `${lists}/crm/orgs`,
    personsPath: () => `${lists}/crm/persons`,
    crossPath: () => `${lists}/crm/cross`,
  },

  finance: {
    rootPath: financeRootPath,
    outcomingInvoices: {
      allPath: outcomingInvoicesPath,
      readyForCheckPath: () => `${outcomingInvoicesPath()}/ready_for_check`,
      my: {
        allPath: () => `${outcomingInvoicesPath()}/my`,
        needFixesPath: () => `${outcomingInvoicesPath()}/my/need_fixes`,
        notYetPaidPath: () => `${outcomingInvoicesPath()}/my/not_yet_paid`,
      },
    },
    paymentBalance: {
      detailedPath: () => `${lists}/finance/payment_balance/balance_detailed`,
      overviewPath: () => `${lists}/finance/payment_balance/balance_overview`,
    },
    customersBalancesPath: () => `${lists}/finance/customers_balances`,
    customersCashPath: () => `${lists}/finance/customers_cash`,
    partnerInvoices: {
      old: {
        allPath: () => `${oldPartnerInvoicesPath()}/all`,
        myToProcessPath: () => `${oldPartnerInvoicesPath()}/my_to_process`,
        dashPath: () => `${oldPartnerInvoicesPath()}/dash`,
        toEnterToDatevPath: () => `${oldPartnerInvoicesPath()}/to_enter_to_datev`,
        toEnterToIntacctPath: () => `${oldPartnerInvoicesPath()}/to_enter_to_intacct`,
        toEnterToAccountingIdPath: () =>
          `${oldPartnerInvoicesPath()}/to_enter_to_${getAccountingIdName().toLowerCase()}`,
        toBePaidPath: () => `${oldPartnerInvoicesPath()}/to_be_paid`,
        missingOrMismatchPath: () => `${oldPartnerInvoicesPath()}/missing_or_mismatch`,
      },
      new: {
        partnersSidePath: () => `${newPartnerInvoicesPath()}/partners_side`,
        allPath: () => `${newPartnerInvoicesPath()}/all`,
        myToProcessPath: () => `${newPartnerInvoicesPath()}/my_to_process`,
        toCheckPath: () => `${newPartnerInvoicesPath()}/to_check`,
        toEnterToDatevPath: () => `${newPartnerInvoicesPath()}/to_enter_to_datev`,
        toEnterToIntacctPath: () => `${newPartnerInvoicesPath()}/to_enter_to_intacct`,
        toEnterToAccountingIdPath: () =>
          `${newPartnerInvoicesPath()}/to_enter_to_${getAccountingIdName().toLowerCase()}`,
        toBePaidPath: () => `${newPartnerInvoicesPath()}/to_be_paid`,
      },
    },
  },

  sales: {
    rootPath: () => `${lists}/sales`,
    quotesPath: () => `${lists}/sales/quotes`,
    analytics: {
      ordersCurrentMonthPath: () => `${lists}/sales/analytics/orders_current_month`,
      bdmPerformancePath: () => `${lists}/sales/analytics/bdm_performance`,
      kamQuotationsPath: () => `${lists}/sales/analytics/kam_quotations`,
      customerBasePath: () => `${lists}/sales/analytics/customer_base`,
      monthlySalesMetricsPath: () => `${lists}/sales/analytics/monthly_sales_metrics`,
      npg: () => `${lists}/sales/analytics/npg`,
      clientDevelopmentDashboard: () => `${lists}/sales/analytics/client_development_dashboard`,
      wonDealsPath: () => `${lists}/sales/analytics/won_deals`,
      lostDealsPath: () => `${lists}/sales/analytics/lost_deals`,
      salesTargetsPath: () => `${lists}/sales/analytics/sales_targets`,
      salespeoplePerformancePath: () => `${lists}/sales/analytics/salespeople_performance`,
    },
  },

  drawings: {
    rootPath: () => `${lists}/drawings`,
    tagErrors: () => `${lists}/drawings/tag_errors`,
  },

  production: {
    rootPath: () => `${lists}/production`,
    warehouseOverviewPath: () => `${lists}/production/warehouse_overview`,
    dfmDrawingsOverviewPath: () => `${lists}/production/dfm_drawings_overview`,
    po: {
      noHsPath: () => `${lists}/production/production_orders_no_hs`,
    },
    productionOrdersPath: () => `${lists}/production/production_orders`,
    daily: {
      indicatorsPath: () => `${lists}/production/daily/indicators`,
    },
    pm: {
      workloadPath: () => `${lists}/production/pm/workload`,
    },
    differenceAnalysis: () => `${lists}/production/difference_analysis`,
    quality: {
      qualityControlPath: () => `${lists}/production/quality/control`,
    },
    jobs: {
      listPath: () => `${lists}/production/jobs/list`,
      lackOfGraphic: () => `${lists}/production/jobs/lack_of_graphic`,
      infoPath: () => `${lists}/production/jobs/info`,
      generalJobBoardPath: () => `${lists}/production/jobs/general_job_board`,
      offers: {
        allPath: () => `${lists}/production/jobs/offers/all`,
        decisionNeededPath: () => `${lists}/production/jobs/offers/decision_needed`,
      },
    },
  },

  logistics: {
    rootPath: () => `${lists}/logistics`,
    warehousePath: () => `${lists}/logistics/warehouse`,
  },

  partners: {
    rootPath: () => `${lists}/partners`,
    kpiPath: () => `${lists}/partners/kpi`,
    instantPayPath: () => `${lists}/partners/instant_pay`,
    expressOrdersPath: () => `${lists}/partners/express_orders`,
    network: {
      claimAndDelayPath: () => `${lists}/partners/network/claim_and_delay`,
      providersByCohortsPath: () => `${lists}/partners/network/providers_by_cohorts`,
      generalInfoPath: () => `${lists}/partners/network/general_info`,
      competenceBalancePath: () => `${lists}/partners/network/competence_balance`,
      profilesPath: () => `${lists}/partners/network/profiles`,
      newbiesPath: () => `${lists}/partners/network/newbies`,
      performancePath: () => `${lists}/partners/network/performance`,
    },
    cPool: {
      posPath: () => `${lists}/partners/c_pool/po`,
      partnersPath: () => `${lists}/partners/c_pool/partners`,
      analyticsPath: () => `${lists}/partners/c_pool/analytics`,
    },
    engagement: {
      lifetimeMilestonesPath: () => `${lists}/partners/engagement/lifetime_milestones`,
      onboardingPath: () => `${lists}/partners/engagement/onboarding`,
      probationPath: () => `${lists}/partners/engagement/probation`,
      testPartPath: () => `${lists}/partners/engagement/test_part`,
      trustedPath: () => `${lists}/partners/engagement/trusted`,
      poByCountryAnalysisPath: () => `${lists}/partners/engagement/by_country_analysis`,
    },
  },
};

export default lookers;
