import { gql } from 'apollo-boost';

export const PAYMENTS_CREATE_EXPORT = gql`
  mutation paymentsCreateExportToDatev(
    $ids: [ID!]
    $period: String!
    $selectedType: String!
    $methodType: String
    $durationFrom: String
    $durationTo: String
  ) {
    paymentsCreateExportToDatev(
      input: {
        ids: $ids
        period: $period
        selectedType: $selectedType
        methodType: $methodType
        durationFrom: $durationFrom
        durationTo: $durationTo
      }
    ) {
      datevPaymentsExport {
        id
        createdAt
        state
        selectedSize
        user {
          id
          name
        }
      }
    }
  }
`;

export const PAYMENTS_UPDATE_REGISTERED_AT = gql`
  mutation paymentsUpdateRegisteredAt($ids: [ID!], $overwrite: Boolean!, $registeredAt: String!) {
    paymentsUpdateRegisteredAt(input: { ids: $ids, overwrite: $overwrite, registeredAt: $registeredAt }) {
      payments {
        id
        registeredAt
      }
    }
  }
`;
