import { Col, Row } from 'antd';
import { IProvider } from 'interfaces/graphql';
import React, { FC } from 'react';

import Info from './Info';
import PartnerMain from './PartnerMain';
import Rating from './Rating';
// import DealOrgPerson from './DealOrgPerson';
import { HeaderCol, HeaderContainer, PartnerHeaderCol } from './styled';

interface IProps {
  provider: IProvider;
}

const PartnerHeader: FC<IProps> = ({ provider }) => (
  <HeaderContainer>
    <HeaderCol xs={24} sm={4} xxl={3}>
      <PartnerMain provider={provider} />
    </HeaderCol>

    <PartnerHeaderCol xs={24} sm={20} xxl={21}>
      <Row justify="space-between" wrap={false}>
        <Col>
          <Info provider={provider} />
        </Col>
        <Col>
          {/* eslint-disable react/jsx-props-no-spreading */}
          <Rating {...provider.rank} providerId={provider.id} />
          {/* eslint-enable react/jsx-props-no-spreading */}
        </Col>
      </Row>
    </PartnerHeaderCol>
  </HeaderContainer>
);

export default PartnerHeader;
