/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CurrencyEnum, PaymentTermsTypeEnum } from '__generated__/types';
import { FormInstance, Modal } from 'antd';
import { AxiosResponse } from 'axios';
import Notification from 'components/UI/Notification';
import { objectBorder } from 'components/UI/variables';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';
import { getRegionCurrencySymbol } from 'config/regions/utils';
import {
  InvoiceState,
  IPaymentRelation,
  OutInvoiceFormValues,
  Position,
  ServerGetInvoice,
  ServerPosition,
  ServerUpdateInvoice,
} from 'interfaces';
import { get } from 'lodash-es';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import UpdatableStore from 'stores/shared/UpdatableStore';
import omsApi from 'utils/axios';
import useDownload from 'utils/hooks/useDownload';
import { getCurrencySymbol } from 'utils/money';

import { initialValuesOutInvoice, mapServerInvoice } from './utils';

interface LastInvoice {
  name: string;
  issuedAt: string;
}

class DealOutInvoiceEditStore extends UpdatableStore {
  dealId = '';

  invoiceId = '';

  form: FormInstance<unknown> | undefined;

  @observable currencySymbol = getCurrencySymbol(this.rootStore.dealStore.data.currency) || getRegionCurrencySymbol();

  @observable data: ServerGetInvoice | null = null;

  @observable publishStatus = {
    isFinished: false,
    isProcessing: false,
    error: false,
  };

  @observable initialPositions: Position[] = [];

  @observable lastInvoice: LastInvoice | undefined = undefined;

  @observable isCreditNote = false;

  @observable isIntercompany = false;

  @observable errors?: Record<string, string[]>;

  @observable isStorno = false;

  @observable initialValuesLoaded = false;

  @observable initialValues: OutInvoiceFormValues = initialValuesOutInvoice();

  @observable paymentRelations: IPaymentRelation[] = [];

  @observable paymentInfoComment: string | undefined | null = undefined;

  @observable toBePaidCurrency: CurrencyEnum | undefined = undefined;

  @observable toBePaidAmount: string | number | undefined = undefined;

  @observable toBePaidInDifferentCurrency: boolean | undefined = false;

  @observable baCurrency: CurrencyEnum | undefined = undefined;

  @action setPaymentInfoComment = (paymentInfoComment: string | undefined | null) => {
    this.paymentInfoComment = paymentInfoComment;
  };

  @action setBaCurrency = (baCurrency: CurrencyEnum | undefined) => {
    this.baCurrency = baCurrency;
  };

  @action setToBePaidCurrency = (toBePaidCurrency: CurrencyEnum | undefined) => {
    this.toBePaidCurrency = toBePaidCurrency;
  };

  @action setToBePaidAmount = (toBePaidAmount: string | number | undefined) => {
    this.toBePaidAmount = toBePaidAmount;
  };

  @action setToBePaidInDifferentCurrency = (toBePaidInDifferentCurrency: boolean | undefined) => {
    this.toBePaidInDifferentCurrency = toBePaidInDifferentCurrency;
  };

  mapData = (data: any): ServerUpdateInvoice => mapServerInvoice(data);

  @action manualSetInvoiceState = (state: InvoiceState) => {
    if (this.data) {
      this.data = { ...this.data, state };
    }
  };

  @action setPaymentRelations = (paymentRelations: IPaymentRelation[]) => {
    this.paymentRelations = paymentRelations;
  };

  @action assignOnUpdateSuccess = (
    payload: {
      // eslint-disable-next-line camelcase
      invoice_positions: ServerPosition[];
    },
    goToPublished = false,
  ) => {
    const positions = this.rootStore.dealOutInvoicePositionsStore.parsePositions(payload.invoice_positions);

    this.rootStore.dealOutInvoicePositionsStore.setPositions(positions);
    this.rootStore.dealOutInvoicePositionsStore.setTableLoading(false);

    if (this.data) {
      if (goToPublished) {
        window.location.href = routes.editPublishedOutcomingInvoicePath(this.dealId, this.data.id);
      } else {
        window.location.reload();
      }
    }
  };

  @action doOnDeleteSuccess = () => {
    window.location.pathname = routes.newOutcomingInvoicePath(this.dealId);
  };

  @action assignOnShowSuccess = (payload: ServerGetInvoice) => {
    this.data = payload;
    this.setCurrencySymbol(this.data.currency);
  };

  @action setIsCreditNote = (isCreditNote: boolean) => {
    this.isCreditNote = isCreditNote;
  };

  @action setIsStorno = (isStorno: boolean) => {
    this.isStorno = isStorno;
  };

  @action setCurrencySymbol = (currency?: string) => {
    this.currencySymbol = getCurrencySymbol(currency);
  };

  @action initLoad = (dealId: string, invoiceId: string) => {
    this.rootStore.dealOutInvoicePositionsStore.setTableLoading(true);
    this.showStatus.isFinished = false;
    this.show(routesApi.dealOutcomingInvoicePath(dealId, invoiceId));
  };

  @action reset = () => {
    this.rootStore.dealOutInvoiceEditStore = new DealOutInvoiceEditStore(this.rootStore);
    this.rootStore.dealOutInvoicesStore.reset();
    this.rootStore.dealOutInvoicePositionsStore.reset();
    this.rootStore.dealOutInvoiceCommentStore.comment = '';
  };

  @action endLoad = () => {
    if (!this.data) {
      return;
    }

    const positions = this.rootStore.dealOutInvoicePositionsStore.parsePositions(this.data.invoice_positions);

    this.rootStore.dealOutInvoicePositionsStore.setPositions(positions);
    this.rootStore.dealOutInvoicePositionsStore.setTableLoading(false);
    this.rootStore.uiStore.setContentBorderColor(
      // eslint-disable-next-line camelcase
      objectBorder[this.data?.credit_note ? 'g' : 'r'],
    );
    this.isCreditNote = this.data.credit_note;
    this.isIntercompany = this.data.is_intercompany;
    this.isStorno = this.data.is_storno;
    this.initialValuesLoaded = true;
    this.setInitialValues();
    this.initialPositions = positions;
    this.setToBePaidInDifferentCurrency(this.data.to_be_paid_in_different_currency);
    this.setToBePaidCurrency(this.data.to_be_paid_amount_money?.currency_code);
  };

  @action publish = (url: string, data: unknown) => {
    this.rootStore.pageLoaderStore.enable('Publishing...');
    const mappedData = this.mapData({ ...(data as object), paymentRelations: this.paymentRelations });
    omsApi.post(url, mappedData).then(this.onPublishSuccess, (error) => this.onPublishError(error));
  };

  @action onPublishSuccess = () => {
    this.rootStore.pageLoaderStore.disable();

    window.location.reload();
  };

  @action cleanErrors = () => {
    this.errors = undefined;
  };

  @action onPublishError = (error: any) => {
    const errors = get(error, 'response.data.errors.source');
    const issuedAtError = get(error, 'response.data.errors.source.issued_at');
    const shippingDateError = get(error, 'response.data.errors.source.positions_delivery_date');
    const paymentTermsDateError = get(error, 'response.data.errors.source.payment_terms_date');

    if (this.form && issuedAtError) {
      this.form.setFields([
        {
          name: 'issuedAt',
          errors: issuedAtError,
        },
      ]);
    }

    if (this.form && paymentTermsDateError) {
      this.form.setFields([
        {
          name: 'paymentDueDate',
          errors: paymentTermsDateError,
        },
      ]);
    }

    if (this.form && shippingDateError) {
      this.form.setFields([
        {
          name: 'positionsDeliveryDate',
          errors: shippingDateError,
        },
      ]);
    }

    if (errors) {
      Modal.error({
        title: 'Publishing error',
        content: Object.values(errors).join(', '),
      });
    }

    this.rootStore.pageLoaderStore.disable();
    this.rootStore.activityRecordStore.refetch();

    Notification.Error('Failed to publish');
  };

  @action fixPublished = (url: string, data: unknown) => {
    this.rootStore.pageLoaderStore.enable('Updating');
    this.updateStatus.isProcessing = true;

    omsApi.patch(url, this.mapData(data)).then(this.onFixSuccess, this.onUpdateError);
  };

  @action onUpdateError = (error: any): void => {
    const errors = get(error, 'response.data.errors.source');
    const shippingDateError = get(error, 'response.data.errors.source.positions_delivery_date');

    if (this.form && shippingDateError) {
      this.form.setFields([
        {
          name: 'positionsDeliveryDate',
          errors: shippingDateError,
        },
      ]);
    }

    if (errors) {
      Modal.error({
        title: 'Publishing error',
        content: Object.values(errors).join(', '),
      });
    }

    this.rootStore.pageLoaderStore.disable();
    this.rootStore.dealOutInvoicePositionsStore.setTableLoading(false);

    Notification.Error('Failed to Update');
  };

  @action onFixSuccess = (response: AxiosResponse) => {
    const { payload } = response.data;
    this.assignOnUpdateSuccess(payload, true);

    this.updateStatus.isFinished = true;
    this.updateStatus.isProcessing = false;
    this.rootStore.pageLoaderStore.disable();
  };

  @action preview = (url: string, data: unknown) => {
    this.rootStore.pageLoaderStore.enable('Updating to form the preview...');

    omsApi.patch(url, this.mapData(data)).then(this.onPreviewSuccess, this.onUpdateError);
  };

  @action onPreviewSuccess = () => {
    const downloader = useDownload();

    void downloader(routesApi.outcomingInvoiceExportPath(this.invoiceId), `${this.invoiceId}.pdf`).then(() =>
      window.location.reload(),
    );
  };

  @action updatePreview = (url: string, data: unknown) => {
    this.rootStore.pageLoaderStore.enable('Updating to form the preview...');

    omsApi.patch(url, this.mapData(data)).then(this.onUpdatePreviewSuccess, this.onUpdateError);
  };

  @action onUpdatePreviewSuccess = () => {
    const downloader = useDownload();

    void downloader(routesApi.outcomingInvoicePreviewPath(this.invoiceId), `${this.invoiceId}.pdf`).then(() =>
      window.location.reload(),
    );
  };

  setInitialValues = () => {
    if (!this.data) {
      return;
    }

    const defaultValues = {
      creditNote: this.data.credit_note,
      creditNoteReason: this.data.credit_note_reason,
      creditNoteReasonClaimId: this.data.credit_note_reason_claim_id,
      isStorno: this.data.is_storno,
      issuedAt: moment(this.data.issued_at),
      refInvoiceId: this.data.ref_invoice_id,

      comment: this.data.comment,
      datevComment: this.data.datev_comment,
      intacctComment: this.data.intacct_comment,
      paidAt: this.data.paid_at ? moment(this.data.paid_at) : undefined,

      purchaseOrderNumber: this.data.purchase_order_number,
      purchaseOrderDate: this.data.purchase_order_date ? moment(this.data.purchase_order_date) : undefined,

      invoiceReady: {
        by: this.data.invoice_ready ? this.data.invoice_ready.by : '',
        at: this.data.invoice_ready ? this.data.invoice_ready.at : '',
        checked: this.data.invoice_ready ? this.data.invoice_ready.by !== '' : false,
      },
      invoiceChecked: {
        by: this.data.invoice_checked ? this.data.invoice_checked.by : '',
        at: this.data.invoice_checked ? this.data.invoice_checked.at : '',
        checked: this.data.invoice_checked ? this.data.invoice_checked.by !== '' : false,
      },
      invoiceNeedFix: {
        by: this.data.invoice_need_fix ? this.data.invoice_need_fix.by : '',
        at: this.data.invoice_need_fix ? this.data.invoice_need_fix.at : '',
        checked: this.data.invoice_need_fix ? this.data.invoice_need_fix.by !== '' : false,
      },

      positionsDeliveryDate: this.data.positions_delivery_date ? moment(this.data.positions_delivery_date) : undefined,
      refPositionsSources: this.data.ref_positions_sources,

      paymentDue: this.data.payment_terms_days,
      paymentDueDate: this.data.payment_terms_date ? moment(this.data.payment_terms_date) : undefined,
      paymentTermsType: this.data.payment_terms_type,

      addDiscount: !!(this.data.payment_terms_discount_percentage || this.data.payment_terms_discount_date),

      discountDueDays: this.data.payment_terms_discount_days,
      discountDueDate: this.data.payment_terms_discount_date
        ? moment(this.data.payment_terms_discount_date)
        : undefined,
      discountPercentage: this.data.payment_terms_discount_percentage,

      isPublishedInCA: this.data.is_published_in_ca,
      emailInvoiceOnPublish: this.data.email_invoice_on_publish,
      sendReminders: this.data.send_reminders,

      sachkontoNumber: this.data.sachkonto_number?.toString(),

      positions: this.rootStore.dealOutInvoicePositionsStore.tablePositions,

      billing: {
        id: this.data.billing?.id,
        datevId: this.data.billing?.datev_id,
        intacctId: this.data.billing?.intacct_id,
        name: this.data.billing?.name,
        address: this.data.billing?.address,
        zip: this.data.billing?.zip,
        city: this.data.billing?.city,
        country: this.data.billing?.country,
        taxId: this.data.billing?.tax_id,
        vatId: this.data.billing?.vat_id,
        vatValidationStatus: this.data.billing?.vat_validation_status,
        type: this.data.billing?.type,
        accountType: this.data.billing?.account_type,

        contactPerson: this.data.person_name,
        phone: this.data.person_phone,
        email: this.data.person_email,
      },

      toBePaidAmount: this.data.to_be_paid_amount_money?.amount,
      toBePaidCurrency: this.data.to_be_paid_amount_money?.currency_code,
      toBePaidInDifferentCurrency: this.data.to_be_paid_in_different_currency,
    };

    const valuesEU = {
      ...defaultValues,
      datevVatCode: this.data.datev_vat_code?.toString(),
      xometryVatNumberId: this.data.xometry_vat_number?.id,
      overrideAccountingDataDisabled: this.data.override_accounting_data_disabled,

      xometryVatNumber: this.data.xometry_vat_number
        ? {
            id: this.data.xometry_vat_number.id,
            country: this.data.xometry_vat_number.country,
            number: this.data.xometry_vat_number.number,

            validation:
              this.data.xometry_vat_number.validation == null
                ? undefined
                : moment(this.data.xometry_vat_number.validation).toDate(),

            reverseCharge: this.data.xometry_vat_number.reverse_charge,
            reverseChargeRemark: this.data.xometry_vat_number.reverse_charge_remark,
          }
        : undefined,
    };

    const valuesConfig: Record<Regions, OutInvoiceFormValues> = {
      [Regions.EU]: valuesEU,
      [Regions.TR]: defaultValues,
      [Regions.UK]: defaultValues,
    };

    this.initialValues = valuesConfig[CURRENT_REGION];
  };

  /** Set paymentDueDays and paymentTermsType to initial values after update payment due */
  @action setInitialPaymentDue = ({
    paymentDue,
    paymentTermsType,
  }: {
    paymentDue: number;
    paymentTermsType: PaymentTermsTypeEnum;
  }) => {
    this.initialValues.paymentDue = paymentDue;
    this.initialValues.paymentTermsType = paymentTermsType;
  };

  @computed get isDraft(): boolean {
    return this.data?.state === 'draft';
  }
}

export default DealOutInvoiceEditStore;
