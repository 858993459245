import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';
import moment from 'moment';

const invoicesUrl = '/embed/dashboards/138';

const myInvoicesUrl = (name: string) => {
  const uriName = encodeURIComponent(name);

  return `${invoicesUrl}}?Type=&State=&Customer=&Issued=&Registered=&Payment%20due=&Paid=&Brutto%20value=&Production%20manager=${uriName}&filter_config=%7B"Type":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":18%7D%5D,"State":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":19%7D%5D,"Customer":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":20%7D%5D,"Issued":%5B%7B"type":"anytime","values":%5B%7B"date":"2020-09-16T15:33:17.693Z","constant":"7","unit":"day","tz":true%7D,%7B%7D%5D,"variant":"absolute","id":27%7D%5D,"Registered":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":22%7D%5D,"Payment%20due":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":23%7D%5D,"Paid":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":24%7D%5D,"Brutto%20value":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":25%7D%5D,"Production%20manager":%5B%7B"type":"%3D","values":%5B%7B"constant":"${uriName}"%7D,%7B%7D%5D,"id":26%7D%5D%7D`;
};

const myNeedFixes = (name: string) => {
  const uriName = encodeURIComponent(name);

  return `${invoicesUrl}?Type=&State=need%20fixes&Customer=&Issued=&Registered=&Payment%20due=&Paid=&Brutto%20value=&Production%20manager=${uriName}&filter_config=%7B"Type":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":18%7D%5D,"State":%5B%7B"type":"%3D","values":%5B%7B"constant":"need%20fixes"%7D,%7B%7D%5D,"id":19%7D%5D,"Customer":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":20%7D%5D,"Issued":%5B%7B"type":"anytime","values":%5B%7B"date":"2020-09-16T15:33:17.693Z","constant":"7","unit":"day","tz":true%7D,%7B%7D%5D,"variant":"absolute","id":27%7D%5D,"Registered":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":22%7D%5D,"Payment%20due":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":23%7D%5D,"Paid":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":24%7D%5D,"Brutto%20value":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":25%7D%5D,"Production%20manager":%5B%7B"type":"%3D","values":%5B%7B"constant":"${uriName}"%7D,%7B%7D%5D,"id":26%7D%5D%7D`;
};

const myNotYetPaid = (name: string) => {
  const uriName = encodeURIComponent(name);

  return `${invoicesUrl}?Type=&State=published&Customer=&Issued=&Registered=&Payment%20due=&Paid=NULL&Brutto%20value=&Production%20manager=${uriName}&filter_config=%7B"Type":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":18%7D%5D,"State":%5B%7B"type":"%3D","values":%5B%7B"constant":"published"%7D,%7B%7D%5D,"id":19%7D%5D,"Customer":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":20%7D%5D,"Issued":%5B%7B"type":"anytime","values":%5B%7B"date":"2020-09-16T15:33:17.693Z","constant":"7","unit":"day","tz":true%7D,%7B%7D%5D,"variant":"absolute","id":27%7D%5D,"Registered":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":22%7D%5D,"Payment%20due":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":23%7D%5D,"Paid":%5B%7B"type":"null","values":%5B%7B%7D,%7B%7D%5D,"id":24%7D%5D,"Brutto%20value":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":25%7D%5D,"Production%20manager":%5B%7B"type":"%3D","values":%5B%7B"constant":"${uriName}"%7D,%7B%7D%5D,"id":26%7D%5D%7D`;
};

const myInvoicesToProcess = (name: string) => {
  const uriName = encodeURIComponent(name);

  return `/embed/dashboards-next/82?Sales%20Assistant=${uriName}&Provider=&filter_config=%7B"Sales%20Assistant":%5B%7B"type":"%3D","values":%5B%7B"constant":"${uriName}"%7D,%7B%7D%5D,"id":4%7D%5D,"Provider":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":5%7D%5D%7D`;
};

const newMyInvoicesToProcess = (name: string) => {
  const uriName = encodeURIComponent(name);
  const tomorrow = encodeURIComponent(moment().add(1, 'days').format('YYYY-MM-DD'));

  return `/embed/dashboards/286?Arrival%20date=&Approval=Prepared%20by%20Partner,Manager%20is%20fixing&Hold%20till%20date=before%20${tomorrow},NULL&Production%20manager=${uriName}&Show%20deleted=No`;
};

const today = moment().format('YYYY-MM-DD');

const customerBalancesConfig: Record<Regions, string> = {
  [Regions.EU]: `/embed/dashboards/2676?Kam=&Pipedrive ID=&Org. name=&Fin Region=&Country=&PM=&Overdue Dunning=&On Date=${today}&Process Type=&Paid Date=&Pod ID=&Has Active Claim (Yes %2F No)=No&Cash Collection Status=&Intercompany=&Negotiated Due Date=&Invoice Balance=>%3D1`,
  [Regions.TR]: `/embed/dashboards/2780?On%20Date=${today}&Overdue=>0`,
  [Regions.UK]: `/embed/dashboards/3333?On%20Date=${today}&Overdue=>0`,
};

const clientDevelopmentDashboardByRegion: Record<Regions, string> = {
  [Regions.EU]: '/embed/dashboards/2324?Org+Name=Northvolt+AB&Period=after+2020%2F01%2F01',
  [Regions.TR]:
    '/embed/dashboards/2324?Org+Name=BAMA+TEKNOLOJ%C4%B0+TIBBI+C%C4%B0HAZLAR+DANI%C5%9EMANLIK+SA%C4%9ELIK+B%C4%B0L%C4%B0%C5%9E%C4%B0M+SANAY%C4%B0+VE+T%C4%B0CARET+L%C4%B0M%C4%B0TED+%C5%9E%C4%B0RKET%C4%B0&Period=after+2020%2F01%2F01',
  [Regions.UK]: '/embed/dashboards/2324?Org+Name=Penlon+Ltd&Period=after+2020%2F01%2F01',
};

const wonDealsByRegion: Record<Regions, string> = {
  [Regions.EU]:
    '/embed/dashboards/2560?Deal+Process=&Sales+Person+Region=&Salesperson=&Deal+Process+Group=&Customer+Order+Date=&ERP+Instance=EU&Quote+Type=&Deal+Created+At=this+year&Customer+Order+Grade=&Deal+Material+Type=&Customer+Type=',
  [Regions.TR]:
    '/embed/dashboards/2560?Deal+Process=&Sales+Person+Region=&Salesperson=&Deal+Process+Group=&Customer+Order+Date=&ERP+Instance=TR&Quote+Type=&Deal+Created+At=this+year&Customer+Order+Grade=&Deal+Material+Type=&Customer+Type=',
  [Regions.UK]:
    '/embed/dashboards/2560?Deal+Process=&Sales+Person+Region=&Salesperson=&Deal+Process+Group=&Customer+Order+Date=&ERP+Instance=UK&Quote+Type=&Deal+Created+At=this+year&Customer+Order+Grade=&Deal+Material+Type=&Customer+Type=',
};

const lstDealsByRegion: Record<Regions, string> = {
  [Regions.EU]:
    '/embed/dashboards/2141?Deal+Process=&Sales+Person+Region=&Salesperson=&Reason+of+Lose=before+order%3A+too+long+delivery%2Cbefore+order%3A+too+high+price%2Cbefore+order%3A+shipping+price+too+high%2Cbefore+order%3A+refused+by+xom+%28out+of+scope%29%2Cbefore+order%3A+quoted+too+late%2Cbefore+order%3A+prefer+local+supplier%2Cbefore+order%3A+prefer+in-house+production%2Cbefore+order%3A+customer+put+the+project+on+hold%2Cbefore+order%3A+client+unhappy+with+our+past+service&Deal+Process+Group=&Customer+Order+Date=&ERP+Instance=EU&Quote+Type=&Deal+Created+At=2023&Customer+Order+Grade=&Deal+Material+Type=&Customer+Type=',
  [Regions.TR]:
    '/embed/dashboards/2141?Deal+Process=&Sales+Person+Region=&Salesperson=&Reason+of+Lose=before+order%3A+too+long+delivery%2Cbefore+order%3A+too+high+price%2Cbefore+order%3A+shipping+price+too+high%2Cbefore+order%3A+refused+by+xom+%28out+of+scope%29%2Cbefore+order%3A+quoted+too+late%2Cbefore+order%3A+prefer+local+supplier%2Cbefore+order%3A+prefer+in-house+production%2Cbefore+order%3A+customer+put+the+project+on+hold%2Cbefore+order%3A+client+unhappy+with+our+past+service&Deal+Process+Group=&Customer+Order+Date=&ERP+Instance=TR&Quote+Type=&Deal+Created+At=2023&Customer+Order+Grade=&Deal+Material+Type=&Customer+Type=',
  [Regions.UK]:
    '/embed/dashboards/2141?Deal+Process=&Sales+Person+Region=&Salesperson=&Reason+of+Lose=before+order%3A+too+long+delivery%2Cbefore+order%3A+too+high+price%2Cbefore+order%3A+shipping+price+too+high%2Cbefore+order%3A+refused+by+xom+%28out+of+scope%29%2Cbefore+order%3A+quoted+too+late%2Cbefore+order%3A+prefer+local+supplier%2Cbefore+order%3A+prefer+in-house+production%2Cbefore+order%3A+customer+put+the+project+on+hold%2Cbefore+order%3A+client+unhappy+with+our+past+service&Deal+Process+Group=&Customer+Order+Date=&ERP+Instance=UK&Quote+Type=&Deal+Created+At=2023&Customer+Order+Grade=&Deal+Material+Type=&Customer+Type=',
};

const salesTargetsByRegion: Record<Regions, string> = {
  [Regions.EU]: '/embed/dashboards/2403',
  [Regions.TR]: '/embed/dashboards/2403?Kam=&Sales+Person+Region=Turkey&Job+Title=&Month+Date=this+quarter',
  [Regions.UK]: '/embed/dashboards/2403?Kam=&Sales+Person+Region=UK%2FIE&Job+Title=&Month+Date=this+year',
};

const salespeoplePerformanceByRegion: Record<Regions, string> = {
  [Regions.EU]:
    '/embed/dashboards/3232?Kam=&Sales+Person+Region=DACH&Job+Title=&Period=this+year&Date+Granularity=Month',
  [Regions.TR]:
    '/embed/dashboards/3232?Sales+Person=&Sales+Person+Region=Turkey&Job+Title=&Period=this+year&Date+Granularity=Month',
  [Regions.UK]:
    '/embed/dashboards/3232?Sales+Person=&Sales+Person+Region=UK%2FIE&Job+Title=&Period=this+year&Date+Granularity=Month',
};

const customerBalancesLooker = customerBalancesConfig[CURRENT_REGION];
const clientDevelopmentDashboard = clientDevelopmentDashboardByRegion[CURRENT_REGION];
const wonDeals = wonDealsByRegion[CURRENT_REGION];
const lostDeals = lstDealsByRegion[CURRENT_REGION];
const salesTargets = salesTargetsByRegion[CURRENT_REGION];
const salespeoplePerformance = salespeoplePerformanceByRegion[CURRENT_REGION];

export default {
  crm: {
    deals: {
      today: '/embed/dashboards/276?Arrival%20date=1%20days&Deal%20status=open',
      last7Days: '/embed/dashboards/276?Arrival%20date=7%20days&Deal%20status=open',
      todayOrders: '/embed/dashboards/276?Arrival%20date=&Deal%20status=open&Order%20date=1%20days',
      last7DaysOrders: '/embed/dashboards/276?Arrival%20date=&Deal%20status=open&Order%20date=7%20days',
      won2021: '/embed/dashboards/276?Arrival%20date=&Deal%20status=won&Order%20date=2021',
    },
    orgs: '/embed/dashboards/282',
    persons: '/embed/dashboards/279',
    cross: '/embed/dashboards/220',
    promotions: '/embed/dashboards/333',
  },
  finance: {
    outcomingInvoices: {
      all: `${invoicesUrl}?Type=&State=&Customer=&Issued=after%2060%20days%20ago&Registered=&Payment%20due=&Paid=&Brutto%20value=&Production%20manager=&filter_config=%7B"Type":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":18%7D%5D,"State":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":19%7D%5D,"Customer":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":20%7D%5D,"Issued":%5B%7B"type":"after","values":%5B%7B"constant":"60","unit":"day"%7D,%7B%7D%5D,"variant":"relative","id":21%7D%5D,"Registered":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":22%7D%5D,"Payment%20due":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":23%7D%5D,"Paid":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":24%7D%5D,"Brutto%20value":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":25%7D%5D,"Production%20manager":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":26%7D%5D%7D`,
      readyForCheck: `${invoicesUrl}}?Type=&State=ready%20for%20check&Customer=&Issued=after%2060%20days%20ago&Registered=&Payment%20due=&Paid=&Brutto%20value=&Production%20manager=&filter_config=%7B"Type":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":18%7D%5D,"State":%5B%7B"type":"%3D","values":%5B%7B"constant":"ready%20for%20check"%7D,%7B%7D%5D,"id":19%7D%5D,"Customer":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":20%7D%5D,"Issued":%5B%7B"type":"after","values":%5B%7B"constant":"60","unit":"day"%7D,%7B%7D%5D,"variant":"relative","id":21%7D%5D,"Registered":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":22%7D%5D,"Payment%20due":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":23%7D%5D,"Paid":%5B%7B"type":"anytime","values":%5B%7B%7D,%7B%7D%5D,"id":24%7D%5D,"Brutto%20value":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":25%7D%5D,"Production%20manager":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":26%7D%5D%7D`,
      my: {
        all: myInvoicesUrl,
        needFixes: myNeedFixes,
        notYetPaid: myNotYetPaid,
      },
    },
    paymentBalance: {
      overview: '/embed/dashboards/171',
      detailed: '/embed/dashboards/166',
    },

    customerBalances: customerBalancesLooker,

    customersCash: '/embed/dashboards/142',

    partnerInvoices: {
      old: {
        all: '/embed/dashboards/143',
        myToProcess: myInvoicesToProcess,
        dash: '/embed/dashboards/79',
        toEnterToIntacct: '/embed/dashboards/83',
        toEnterToDatev: '/embed/dashboards/83',
        // Why same IDs?
        toEnterToAccountingId: '/embed/dashboards/83',
        toBePaid: '/embed/dashboards/84',
        missingOrMismatch: '/embed/dashboards/86',
      },
      new: {
        all: '/embed/dashboards/284',
        myToProcess: newMyInvoicesToProcess,
        toCheck: '/embed/dashboards/287',
        toEnterToIntacct: '/embed/dashboards/288',
        toEnterToDatev: '/embed/dashboards/288',
        // Why same IDs?
        toEnterToAccountingId: '/embed/dashboards/288',
        toBePaid: '/embed/dashboards/285',
        partnersSide: '/embed/dashboards/289',
      },
    },
  },
  sales: {
    quotes: '/embed/dashboards/329',
    coupons: '/embed/dashboards/333',
    dfmChecks: '/embed/dashboards/209',
    drawingsCleaning: '/embed/dashboards/210',
    analytics: {
      bdmPerformance: '/embed/dashboards/1313',
      ordersCurrentMonth: '/embed/dashboards/1306',
      kamQuotations: '/embed/dashboards/1301',
      monthlySalesMetricsPath: '/embed/dashboards/428',
      npg: '/embed/dashboards/1385',
      customerBase: '/embed/dashboards/207',
      clientDevelopmentDashboard,
      wonDeals,
      lostDeals,
      salesTargets,
      salespeoplePerformance,
    },
  },
  drawings: {
    tagErrors: `/embed/dashboards/1950?Erp+Instance=${CURRENT_REGION}&Audit+Updated+by+Name=&Pipedrive+Status=`,
  },
  production: {
    warehouseOverview: '/embed/dashboards/862',
    dfmDrawingsOverview: '/embed/dashboards/1637',
    productionOrders: '/embed/dashboards/292',
    po: {
      noHs: '/embed/dashboards-next/363',
    },
    daily: {
      indicators: '/embed/dashboards/71',
    },
    pm: {
      workload: '/embed/dashboards/109',
    },
    differenceAnalysis: '/embed/dashboards/3211',
    quality: {
      qualityControl: '/embed/dashboards/178',
    },
    jobs: {
      info: '/embed/dashboards-next/358?Publication+Start+Date=after+1+months+ago&Customer+Grade=Class+A%2CClass+B&Job+State=active&Job+Has+3d+Part=No',
      list: '/embed/dashboards/179',
      lackOfGraphic: '/embed/dashboards-next/295',
      generalBoard:
        '/embed/dashboards/235?Job%20ID=&Tags=%25cnc%25,%25sheet%25,%25other%25&Publication%20Start%20Date=after%202021%2F01%2F01&Start%20Margin=&Hours%20Before%20Placed=&Decision%20State=&Counter%20Offer%20Margin=%5B-1,%201%5D&Job%20State=placed&filter_config=%7B"Job%20ID":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":16%7D%5D,"Tags":%5B%7B"type":"contains","values":%5B%7B"constant":"cnc,sheet,other"%7D,%7B%7D%5D,"id":17%7D%5D,"Publication%20Start%20Date":%5B%7B"type":"after","values":%5B%7B"date":"2021-01-01T11:57:10.456Z","constant":"7","unit":"day","tz":true%7D%5D,"variant":"absolute","id":18%7D%5D,"Start%20Margin":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":19%7D%5D,"Hours%20Before%20Placed":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":20%7D%5D,"Decision%20State":%5B%7B"type":"%3D","values":%5B%7B"constant":""%7D,%7B%7D%5D,"id":21%7D%5D,"Counter%20Offer%20Margin":%5B%7B"type":"between","values":%5B%7B"constant":"-1"%7D,%7B"constant":"1"%7D%5D,"variant":"%5B%5D","id":22%7D%5D,"Job%20State":%5B%7B"type":"%3D","values":%5B%7B"constant":"placed"%7D,%7B%7D%5D,"id":23%7D%5D%7D',
      offers: {
        all: '/embed/dashboards/183',
        navigationAll: '/embed/dashboards/198?Job%20Offer%20Created%20At=after%203%20days%20ago',
        decisionNeeded: '/embed/dashboards/198',
        byJobId: (jobId: number | string) => `/embed/dashboards/183?Job%20ID=${jobId}`,
      },
    },
  },
  logistics: {
    warehouse: '/embed/dashboards/177',
  },
  partners: {
    list: '/embed/dashboards/314',
    kpi: '/embed/dashboards/456',
    instantPay: '/embed/dashboards-next/403',
    expressOrders: '/embed/dashboards-next/560',
    ratingByParts: (providerId: number | string) =>
      `/embed/dashboards/2168?Provider+ID=${providerId}&Current+Erp+Instance=${CURRENT_REGION}&Is+Affecting+Rating+%28Yes+%2F+No%29=Yes`,
    network: {
      claimAndDelay: '/embed/dashboards/857',
      providersByCohorts: '/embed/dashboards/813',
      generalInfo: '/embed/dashboards/267',
      competenceBalance: '/embed/dashboards/117',
      profiles: '/embed/dashboards/164',
      performance: '/embed/dashboards/159',
      newbies: '/embed/dashboards/239',
    },
    cPool: {
      po: '/embed/dashboards/324',
      partners: '/embed/dashboards/321',
      analytics: '/embed/dashboards-next/327',
    },
    engagement: {
      poByCountryAnalysis: '/embed/dashboards/258',
      lifetimeMilestones: '/embed/dashboards/157',
      testPart: '/embed/dashboards/161',
      trusted: '/embed/dashboards/261',
      onboarding: '/embed/dashboards/160',
      probation: '/embed/dashboards/163',
    },
  },
};
