import { Button, Form, Input, Switch, Tooltip } from 'antd';
import {
  BankPaymentsActions,
  BillingAccountsActions,
  ClaimsActions,
  DealsActions,
  ErpPermissionGroup,
  GroupAndAction,
  IncomingInvoicesActions,
  JobsActions,
  MessageSubscriptionsActions,
  OrganizationBalancesActions,
  OrganizationsActions,
  OutcomingInvoicesActions,
  PartnerBalancesActions,
  PaymentsActions,
  PayoutsActions,
  PersonsActions,
  ProductionOrdersActions,
  QualityControlsActions,
  QuotesActions,
  ShippingAddressesActions,
  ShippingsActions,
  StoragesActions,
  UserManagementActions,
} from 'collections/authorization';
import { QueryNameKey } from 'components/Workspaces/collections';
import { Column } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { FormContainer } from 'components/Workspaces/General/shared/GeneralWorkspace/EditFiltersModal/styled';
import { map } from 'lodash-es';
import React from 'react';
import useCurrentUserPermissions from 'utils/hooks/useCurrentUserPermissions';
import { REQUIRED } from 'validators';

import { IPresetParams } from './interfaces';

const CREATE_PERMISSION_GROUPS: Record<string, GroupAndAction | undefined> = {
  workspacesBankPayments: {
    group: ErpPermissionGroup.BankPayments,
    action: BankPaymentsActions.BankPaymentListUpdatePresets,
  },
  workspacesBankPaymentsDraft: {
    group: ErpPermissionGroup.BankPayments,
    action: BankPaymentsActions.BankPaymentListUpdatePresets,
  },
  workspacesBillingAccounts: {
    group: ErpPermissionGroup.BillingAccounts,
    action: BillingAccountsActions.BillingAccountsListUpdatePresets,
  },
  workspacesClaims: {
    group: ErpPermissionGroup.Claims,
    action: ClaimsActions.ClaimsListUpdatePresets,
  },
  workspacesDeals: {
    group: ErpPermissionGroup.Deals,
    action: DealsActions.DealsListUpdatePresets,
  },
  workspacesFrameworkBatches: {
    group: ErpPermissionGroup.Deals,
    action: DealsActions.DealsListUpdatePresets,
  },
  workspacesEnquiries: {
    group: ErpPermissionGroup.Quotes,
    action: QuotesActions.QuotesListUpdatePresets,
  },
  workspacesIncomingInvoices: {
    group: ErpPermissionGroup.IncomingInvoices,
    action: IncomingInvoicesActions.IncomingInvoicesListUpdatePresets,
  },
  workspacesJobOffers: {
    group: ErpPermissionGroup.Jobs,
    action: JobsActions.JobOffersListUpdatePresets,
  },
  workspacesJobs: {
    group: ErpPermissionGroup.Jobs,
    action: JobsActions.JobsListUpdatePresets,
  },
  workspacesMessageSubscriptions: {
    group: ErpPermissionGroup.MessageSubscriptions,
    action: MessageSubscriptionsActions.MessageSubscriptionsListUpdatePresets,
  },
  workspacesOrganizationBalances: {
    group: ErpPermissionGroup.OrganizationBalances,
    action: OrganizationBalancesActions.OrganizationBalancesListUpdatePresets,
  },
  workspacesOrganizations: {
    group: ErpPermissionGroup.Organizations,
    action: OrganizationsActions.OrganizationsListUpdatePresets,
  },
  workspacesOutcomingInvoices: {
    group: ErpPermissionGroup.OutcomingInvoices,
    action: OutcomingInvoicesActions.OutcomingInvoicesListUpdatePresets,
  },
  workspacesPartnerBalances: {
    group: ErpPermissionGroup.PartnerBalances,
    action: PartnerBalancesActions.PartnerBalancesListUpdatePresets,
  },
  workspacesPayments: {
    group: ErpPermissionGroup.Payments,
    action: PaymentsActions.PaymentsListUpdatePresets,
  },
  workspacesPayouts: {
    group: ErpPermissionGroup.Payouts,
    action: PayoutsActions.PayoutsListUpdatePresets,
  },
  workspacesPersons: {
    group: ErpPermissionGroup.Persons,
    action: PersonsActions.PersonsListUpdatePresets,
  },
  workspacesProviderOrders: {
    group: ErpPermissionGroup.ProductionOrders,
    action: ProductionOrdersActions.ProductionOrdersListUpdatePresets,
  },
  workspacesQualityControls: {
    group: ErpPermissionGroup.QualityControls,
    action: QualityControlsActions.QualityControlsListUpdatePresets,
  },
  workspacesRefunds: {
    group: ErpPermissionGroup.Payments,
    action: PaymentsActions.PaymentsListUpdatePresets,
  },
  workspacesShippingsAddresses: {
    group: ErpPermissionGroup.ShippingAddresses,
    action: ShippingAddressesActions.ShippingAddressesListUpdatePresets,
  },
  workspacesShippings: {
    group: ErpPermissionGroup.Shippings,
    action: ShippingsActions.ShippingsListUpdatePresets,
  },
  workspacesStorages: {
    group: ErpPermissionGroup.Storages,
    action: StoragesActions.StoragesListUpdatePresets,
  },
  workspacesUsers: {
    group: ErpPermissionGroup.UserManagement,
    action: UserManagementActions.UsersListUpdatePresets,
  },
};

interface Props {
  currentColumns: Column[];
  onPresetCreate: (name: string, isPublic: boolean, columns: Column[]) => void;
  queryName: QueryNameKey;
}

const NewPresetForm: React.FC<Props> = ({ currentColumns, onPresetCreate, queryName }) => {
  const [form] = Form.useForm<IPresetParams>();

  const { allPermissions } = useCurrentUserPermissions();

  const handleFormSubmit = (values: IPresetParams) => onPresetCreate(values.name, values.public, currentColumns);

  let publicCheckboxEnabled = false;

  if (allPermissions != null) {
    const workspacesPermissions = map(allPermissions[ErpPermissionGroup.Workspaces], 'name');

    const groupAndAction = CREATE_PERMISSION_GROUPS[queryName];
    const groupPermissions = groupAndAction != null ? map(allPermissions[groupAndAction.group], 'action') : [];

    publicCheckboxEnabled =
      workspacesPermissions.includes('create_public_presets') ||
      groupPermissions.includes(groupAndAction?.action ?? '');
  }

  return (
    <FormContainer>
      <Form
        className="m-t-sm"
        form={form}
        initialValues={{ public: false }}
        layout="horizontal"
        onFinish={handleFormSubmit}
      >
        <Form.Item name="name" label="Preset name:" rules={[{ required: true, message: REQUIRED }]}>
          <Input />
        </Form.Item>

        {publicCheckboxEnabled ? (
          <Form.Item name="public" label="Visible to everyone" valuePropName="checked">
            <Switch />
          </Form.Item>
        ) : (
          <Tooltip title="You don't have permissions to create public presets in this workspace">
            <Form.Item name="public" label="Visible to everyone" valuePropName="checked">
              <Switch disabled />
            </Form.Item>
          </Tooltip>
        )}

        <Button htmlType="submit" type="primary">
          Save preset
        </Button>
      </Form>
    </FormContainer>
  );
};

export default NewPresetForm;
