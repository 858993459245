/* eslint-disable no-useless-escape */
import { Rule } from 'antd/lib/form';

import { emailsToArray } from './formatEmails';
import { INVALID_EMAIL, INVALID_EMAILS } from './messages';

// http://emailregex.com/
export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailValidation = (value: string | null | undefined) => {
  return typeof value === 'string' && value.length <= 320 && !!value.match(EMAIL_REGEXP);
};

/** Validate emails separated by semicolon */
export const emailsValidation = (value: string) => {
  if (!value) {
    return true;
  }

  const emails = emailsToArray(value);

  if (emails.some((email) => email.trim() && !emailValidation(email))) {
    return false;
  }

  return true;
};

/** Validate emails separated by semicolon for Antd Form */
export const emailsValidator: Rule = {
  message: INVALID_EMAILS,
  validator: (rule, value: string) => {
    return emailsValidation(value) ? Promise.resolve() : Promise.reject(INVALID_EMAILS);
  },
};

export const emailValidator: Rule = {
  message: INVALID_EMAIL,
  validator: (rule, value: string | null | undefined) => {
    if (!value) {
      return Promise.resolve();
    }

    return emailValidation(value) ? Promise.resolve() : Promise.reject(INVALID_EMAIL);
  },
};
