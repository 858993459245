import { gql } from 'apollo-boost';

export interface IAuthorizeActionResponse {
  authorizeAction: {
    authorized: boolean;
  };
}

/** @deprecated use authorizeAction.graphql instead */
export const AUTHORIZE_ACTION = gql`
  query authorizeAction($group: String!, $action: String!, $subjectClass: String, $subjectId: Int) {
    authorizeAction(group: $group, action: $action, subjectClass: $subjectClass, subjectId: $subjectId) {
      authorized
    }
  }
`;
