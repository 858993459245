import { SelectProps } from 'antd/lib/select';
import { PermissionsSettings } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { PresetOptionData } from 'components/Workspaces/General/shared/GeneralWorkspace/withPresets';
import { WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import React from 'react';

import PermissionsValidator from './PermissionsValidator';

interface WithPermissionsProps {
  permissionsSettings?: PermissionsSettings;
  preset: WorkspacePreset;
  presetSelector: React.ReactElement<SelectProps<number>>;
  onPresetSelectorAllowClearChange: (allowClear: boolean) => any;
  onPresetSelectorFilter: (filter: (option: PresetOptionData) => boolean) => any;
}

function withPermissions<T>(Component: React.ComponentType<T>) {
  const component: React.FC<T & WithPermissionsProps> = ({
    permissionsSettings,
    preset,
    presetSelector,
    onPresetSelectorAllowClearChange,
    onPresetSelectorFilter,
    ...restProps
  }) => {
    const componentProps = {
      preset,
      presetSelector,
      ...restProps,
    };

    if (permissionsSettings == null) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...(componentProps as any)} />;
    }

    return (
      <PermissionsValidator
        permissionsSettings={permissionsSettings}
        preset={preset}
        presetSelector={presetSelector}
        onPresetSelectorAllowClearChange={onPresetSelectorAllowClearChange}
        onPresetSelectorFilter={onPresetSelectorFilter}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...(componentProps as any)} />
      </PermissionsValidator>
    );
  };

  return component;
}

export default withPermissions;
