import { gql } from 'apollo-boost';
import { ENQUIRY_FRAGMENT, ENQUIRY_PROMOTION_FRAGMENT } from 'utils/graphql/fragments';

export const ENQUIRY_PROMOTIONS_CREATE = gql`
  mutation enquiriesPromotionsCreate($attributes: OmsInputsPromotionType!) {
    enquiriesPromotionsCreate(input: { attributes: $attributes }) {
      enquiryPromotion {
        ...enquiryPromotionFragment
      }
    }
  }
  ${ENQUIRY_PROMOTION_FRAGMENT}
`;

export const ENQUIRY_PROMOTIONS_CREATE_DISCOUNT = gql`
  mutation enquiriesPromotionsCreateDiscount($id: ID!, $attributes: OmsInputsPromotionDiscountType!) {
    enquiriesPromotionsCreateDiscount(input: { id: $id, attributes: $attributes }) {
      enquiry {
        id
        appliedPromotions {
          id
          promotion {
            id
            code
            discountAmount
            internalComment
            discountAmount
            discountUnit
          }
        }
      }
    }
  }
`;

export const ENQUIRY_PROMOTIONS_UPDATE_DISCOUNT = gql`
  mutation enquiriesPromotionsUpdateDiscount($id: ID!, $enquiryId: ID!, $attributes: OmsInputsPromotionDiscountType!) {
    enquiriesPromotionsUpdateDiscount(input: { id: $id, enquiryId: $enquiryId, attributes: $attributes }) {
      enquiryPromotion {
        id
        code
        discountAmount
        internalComment
        discountAmount
        discountUnit
      }
    }
  }
`;

export const ENQUIRY_PROMOTIONS_UPDATE = gql`
  mutation enquiriesPromotionsUpdate($id: ID!, $attributes: OmsInputsPromotionType!) {
    enquiriesPromotionsUpdate(input: { id: $id, attributes: $attributes }) {
      enquiryPromotion {
        ...enquiryPromotionFragment
      }
    }
  }
  ${ENQUIRY_PROMOTION_FRAGMENT}
`;

export const ENQUIRIES_UPDATE = gql`
  mutation enquiriesUpdate($id: ID!, $attributes: OmsInputsEnquiriesEnquiryType!) {
    enquiriesUpdate(input: { id: $id, attributes: $attributes }) {
      enquiry {
        ...enquiryFragment
      }
    }
  }
  ${ENQUIRY_FRAGMENT}
`;

export const GET_ENQUIRY_APPLIED_PROMOTIONS = gql`
  query enquiry($id: ID!) {
    enquiry(id: $id) {
      id
      appliedCouponValue
      appliedPromotions {
        id
        promotion {
          id
          code
          discountAmount
          internalComment
          discountAmount
          discountUnit
        }
      }
    }
  }
`;

export const ENQIURY_PROMOTIONS_DESTROY_DISCOUNT = gql`
  mutation enquiriesPromotionsDestroyDiscount($id: ID!) {
    enquiriesPromotionsDestroyDiscount(input: { id: $id }) {
      enquiry {
        id
        appliedPromotions {
          id
          promotion {
            id
            code
            discountAmount
            internalComment
            discountAmount
            discountUnit
          }
        }
      }
    }
  }
`;

export const ENQUIRIES_BULK_NOTES_UPDATE = gql`
  mutation enquiriesBulkNotesUpdate($partIds: [ID]!, $notes: String!) {
    enquiriesBulkNotesUpdate(input: { partIds: $partIds, notes: $notes }) {
      enquiry {
        id
        parts {
          id
          notes
        }
      }
    }
  }
`;

export const ENQUIRIES_BULK_PARTS_RESTART_WORKFLOW = gql`
  mutation enquiriesBulkActionsPartsRestartWorkflow($partIds: [ID!]!) {
    enquiriesBulkActionsPartsRestartWorkflow(input: { partIds: $partIds }) {
      success
    }
  }
`;
