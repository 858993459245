import { gql } from 'apollo-boost';
import { IDeal } from 'interfaces/graphql/deal';

import { DEAL_FRAGMENT } from '../fragments';

export const DEALS_UPDATE = gql`
  mutation dealsUpdate($id: ID!, $attributes: OmsInputsDealsDealType!) {
    dealsUpdate(input: { id: $id, attributes: $attributes }) {
      deal {
        ...dealFragment
      }
    }
  }
  ${DEAL_FRAGMENT}
`;

export const DEALS_UPDATE_PM = gql`
  mutation dealsUpdateProductionManager($id: ID!, $attributes: OmsInputsDealUpdateProductionManager!) {
    dealsUpdateProductionManager(input: { id: $id, attributes: $attributes }) {
      deal {
        ...dealFragment
      }
    }
  }
  ${DEAL_FRAGMENT}
`;

export const DEALS_CREATE_SLACK_CHANNEL = gql`
  mutation dealsCreateSlackChannel($id: ID!) {
    dealsCreateSlackChannel(input: { id: $id }) {
      deal {
        ...dealFragment
      }
    }
  }
  ${DEAL_FRAGMENT}
`;

export interface IDealsCalcPartsStateResponse {
  dealsCalcPartsState: {
    jobId: string;
  };
}

export const DEALS_CALC_PARTS_STATE = gql`
  mutation dealsCalcPartsState($dealId: ID!) {
    dealsCalcPartsState(input: { dealId: $dealId }) {
      jobId
    }
  }
`;

export interface IDealsCompleteProductionResponse {
  dealsCompleteProduction: {
    deal: IDeal;
  };
}

export const DEALS_COMPLETE_PRODUCTION = gql`
  mutation dealsCompleteProduction($id: ID!) {
    dealsCompleteProduction(input: { id: $id }) {
      deal {
        ...dealFragment
      }
    }
  }
  ${DEAL_FRAGMENT}
`;

export interface IDealsReopenProductionResponse {
  dealsReopenProduction: {
    deal: IDeal;
  };
}

export const DEALS_REOPEN_PRODUCTION = gql`
  mutation dealsReopenProduction($id: ID!) {
    dealsReopenProduction(input: { id: $id }) {
      deal {
        ...dealFragment
      }
    }
  }
  ${DEAL_FRAGMENT}
`;

export const CRM_FORCE_CREATE_DEAL = gql`
  mutation crmForceCreateDeal($id: ID!) {
    crmForceCreateDeal(input: { id: $id }) {
      deal {
        ...dealFragment
      }
    }
  }
  ${DEAL_FRAGMENT}
`;
