import { ErpPermissionGroup } from 'collections/authorization';
import { camelCase, find, upperFirst } from 'lodash-es';
import useCurrentUserPermissions from 'utils/hooks/useCurrentUserPermissions';

const getPermissionPrefix = (permissionsGroup: ErpPermissionGroup) => {
  switch (permissionsGroup) {
    case ErpPermissionGroup.BillingAccounts:
      return 'ba';
    case ErpPermissionGroup.ProductionOrders:
      return 'po';
    case ErpPermissionGroup.QualityControls:
      return 'qc';
    case ErpPermissionGroup.ShippingAddresses:
      return 'sa';
    case ErpPermissionGroup.UserManagement:
      return 'users';
    default:
      return permissionsGroup;
  }
};

const getPermissionGroup = (currentWorkspaceCamelized: string) => {
  switch (currentWorkspaceCamelized) {
    case 'Enquiries':
      return ErpPermissionGroup.Partners;
    case 'Users':
      return ErpPermissionGroup.UserManagement;
    default:
      return ErpPermissionGroup[currentWorkspaceCamelized as keyof typeof ErpPermissionGroup];
  }
};

export const useFiltersEditPermission = ({ pathname }: { pathname: string }) => {
  const { allPermissions } = useCurrentUserPermissions();

  const currentWorkspace = pathname.split('/')[3];
  const currentWorkspaceCamelized = upperFirst(camelCase(currentWorkspace));

  const permissionsGroup = getPermissionGroup(currentWorkspaceCamelized);
  const filterChangePermissionName = `${getPermissionPrefix(permissionsGroup)}_list_filter`;
  const permissionErrorMessage = `Permission "${permissionsGroup}.${filterChangePermissionName}" required to proceed with the action`;

  const isFiltersChangingAllowed = Boolean(
    find(allPermissions?.[permissionsGroup], ({ name }) => name === filterChangePermissionName),
  );

  return {
    isFiltersChangingAllowed,
    permissionErrorMessage,
    permissionsGroup,
  };
};
