import { useMutation, useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { Notification, PageLoader } from 'components/UI';
import { ColumnsMap, QueryNameKey } from 'components/Workspaces/collections';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { filter, find, map, orderBy, pickBy } from 'lodash-es';
import React, { FC, useEffect, useState } from 'react';

import { ISortParam } from './collections';
import {
  IWorkspacePresetsCreateResponse,
  IWorkspacePresetsDeleteResponse,
  IWorkspacePresetsUpdateResponse,
  WORKSPACE_PRESETS_CREATE,
  WORKSPACE_PRESETS_DELETE,
  WORKSPACE_PRESETS_UPDATE,
} from './graphql/mutations';
import {
  GET_DEFAULT_WORKSPACE_PRESET,
  GET_WORKSPACE_PRESETS,
  IDefaultWorkspacePresetResponse,
  IWorkspacePresetsResponse,
} from './graphql/queries';
import s from './preset.module.less';

export interface PresetOptionData {
  label: string;
  value: number;
}

export interface IPresetAttributes {
  name: string;
  isPublic: boolean;
  columns: string[];
  query: string;
  sort?: ISortParam;
}

interface WithPresetsProps {
  // eslint-disable-next-line react/require-default-props
  predefinedPreset?: number;
  columns?: ColumnsMap<any>;
  queryName: QueryNameKey;
}

interface PresettableProps {
  preset: WorkspacePreset;
  presetSelector: React.ReactElement<SelectProps<number>>;
  onPresetSelectorFilter?: (filter: (option: PresetOptionData) => boolean) => any;
  onPresetSelectorAllowClearChange?: (allowClear: boolean) => any;
  onPresetCreate: (attributes: IPresetAttributes) => any;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => any;
  onPresetDelete: (preset: ExistingWorkspacePreset) => any;
}

function withPresets<T extends PresettableProps>(Component: React.ComponentType<T>) {
  const InnerComponent: FC<Omit<T, keyof PresettableProps> & WithPresetsProps> = ({
    columns,
    queryName,
    predefinedPreset,
    ...restProps
  }) => {
    const enabledColumns = Object.keys(pickBy(columns, { enabled: true }));

    const defaultPreset = {
      columns: enabledColumns,
      public: false,
      workspaceName: queryName,
    };

    const [currentPreset, setCurrentPreset] = useState<WorkspacePreset>();
    const [presetFilter, setPresetFilter] = useState<(option: PresetOptionData) => boolean>();
    const [selectorAllowClear, setSelectorAllowClear] = useState(true);

    const { data: userDefaultPresetData, loading: userDefaultPresetLoading } =
      useQuery<IDefaultWorkspacePresetResponse>(GET_DEFAULT_WORKSPACE_PRESET, {
        variables: { workspaceName: queryName },
      });

    const userDefaultPreset = userDefaultPresetData?.defaultWorkspacePreset;

    const getWorkspacePresetsVariables = { workspaceName: queryName };
    const { data: workspacePresetsData, loading: workspacePresetsLoading } = useQuery<IWorkspacePresetsResponse>(
      GET_WORKSPACE_PRESETS,
      {
        skip: userDefaultPresetLoading,
        variables: getWorkspacePresetsVariables,
      },
    );

    const { workspacePresets } = workspacePresetsData || {};
    let workspacePresetOptions: PresetOptionData[] = map(orderBy(workspacePresets, 'name'), (wp) => ({
      label: wp.name,
      value: wp.id,
    }));

    if (presetFilter != null) {
      workspacePresetOptions = filter(workspacePresetOptions, presetFilter);
    }

    const handleCurrentPresetChange = (presetId?: number | string) => {
      const preset = find(workspacePresetsData?.workspacePresets, (wp) => wp.id.toString() === presetId?.toString());

      setCurrentPreset(preset ?? defaultPreset);
    };

    const [createWorkspacePreset] = useMutation<IWorkspacePresetsCreateResponse>(WORKSPACE_PRESETS_CREATE, {
      onCompleted: ({ workspacePresetsCreate: { workspacePreset } }) => handleCurrentPresetChange(workspacePreset.id),
      refetchQueries: [
        {
          query: GET_WORKSPACE_PRESETS,
          variables: getWorkspacePresetsVariables,
        },
      ],
    });

    const [updateWorkspacePreset] = useMutation<IWorkspacePresetsUpdateResponse>(WORKSPACE_PRESETS_UPDATE, {
      onCompleted: ({ workspacePresetsUpdate: { workspacePreset } }) => handleCurrentPresetChange(workspacePreset.id),
    });

    const [deleteWorkspacePreset] = useMutation<IWorkspacePresetsDeleteResponse>(WORKSPACE_PRESETS_DELETE, {
      onCompleted: (_: IWorkspacePresetsDeleteResponse) => {
        handleCurrentPresetChange(undefined);
      },
    });

    useEffect(() => {
      if (workspacePresetsLoading) return;

      if (workspacePresetsData == null) return;

      // 1. Determine preset_id by priority:
      //   * URL parameter
      //   * User's default preset
      //   * Predefined preset for current workspace
      // 2. If there's no preset_id - no preset requested, use plain workspace
      // 3. If preset_id exists - try to find preset by id
      // 4. If preset is found - use it,
      //    if not found - use dummy and show error

      const query = new URLSearchParams(window.location.search);
      const urlPreset = query.get('preset') ?? undefined;
      const presetId = urlPreset ?? userDefaultPreset?.id.toString() ?? predefinedPreset?.toString();

      if (presetId == null) {
        setCurrentPreset(defaultPreset);

        return;
      }

      const preset = find(workspacePresetsData.workspacePresets, (wp) => wp.id.toString() === presetId);

      if (preset != null) {
        setCurrentPreset(preset);
      } else {
        setCurrentPreset(defaultPreset);
        Notification.Error(`Preset "${presetId}" not found`);
      }
      // TODO: Infinite rerendering if fix dependencies
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspacePresetsLoading, workspacePresetsData == null, predefinedPreset]);

    const handlePresetCreate = ({ name, query, columns, isPublic, sort }: IPresetAttributes) => {
      void createWorkspacePreset({
        variables: {
          attributes: {
            name,
            query,
            columns,
            workspaceName: queryName,
            public: isPublic,
            sortColumn: sort?.sortBy,
            sortDirection: sort?.direction,
          },
        },
      });
    };

    const handlePresetUpdate = (preset: ExistingWorkspacePreset) => {
      void updateWorkspacePreset({
        variables: {
          id: preset.id,
          query: preset.query,
          columns: preset.columns,
          exportColumns: preset.exportColumns,
          sortColumn: preset.sortColumn,
          sortDirection: preset.sortDirection,
        },
      });
    };

    const handlePresetDelete = (preset: ExistingWorkspacePreset) => {
      void deleteWorkspacePreset({
        variables: { id: preset.id },
      });
    };

    const presetSelector = (
      <Select
        allowClear={selectorAllowClear}
        onChange={handleCurrentPresetChange}
        options={workspacePresetOptions}
        placeholder="Custom"
        className={s.presetSelector}
        value={currentPreset?.name}
      />
    );

    if (workspacePresetsLoading || currentPreset == null) {
      return <PageLoader title="Loading presets data..." isVisible />;
    }

    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(restProps as any)}
        columns={columns}
        queryName={queryName}
        preset={currentPreset}
        presetSelector={presetSelector}
        onPresetSelectorFilter={(filter) => setPresetFilter(() => filter)}
        onPresetSelectorAllowClearChange={(allowClear) => setSelectorAllowClear(() => allowClear)}
        onPresetCreate={handlePresetCreate}
        onPresetUpdate={handlePresetUpdate}
        onPresetDelete={handlePresetDelete}
      />
    );
  };

  return InnerComponent;
}

export default withPresets;
