import { UserTagView, UserView } from '@xometry/ui';
import Select, { SelectProps } from 'antd/lib/select';
import classNames from 'clsx';
import { filterOption } from 'helpers/filterOption';
import React, { FC, useMemo, useState } from 'react';
import { UserFragmentFragment } from 'utils/graphql/fragments/__generated__/userFragment';
import { useUsers } from 'utils/hooks/useUsers';

import s from './UsersSelect.module.less';

interface IProps extends Pick<SelectProps, 'placeholder' | 'className'> {
  multiple?: boolean;
  onSelect: (value: UserFragmentFragment['id']) => void;
  onDeselect: (value: UserFragmentFragment['id']) => void;
}

const onPreventMouseDown = (event: React.MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

export const UsersSelect: FC<IProps> = ({ onSelect, onDeselect, placeholder, className }) => {
  const [inputValue, setInputValue] = useState<string>('');

  const { realUsers, getUserById } = useUsers();

  const tagRender: SelectProps['tagRender'] = ({ label, onClose, value }): React.ReactElement => {
    const user = getUserById(value as string);

    return (
      <UserTagView
        onMouseDown={onPreventMouseDown}
        name={label as string}
        imageUrl={user?.imageUrl || ''}
        size="small"
        onCrossClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          onClose(e);
        }}
      />
    );
  };

  const sortedRealUsers = useMemo(
    () =>
      [...Object.values(realUsers)].sort((a, b) =>
        a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
      ),
    [realUsers],
  );

  const renderOptions = () =>
    sortedRealUsers.map(({ id, name, imageUrl }) => (
      <Select.Option value={id} label={name} key={id}>
        <UserView name={name} imageUrl={imageUrl || ''} size="small" />
      </Select.Option>
    ));

  const handleSelect: SelectProps<UserFragmentFragment['id']>['onSelect'] = (value) => {
    setInputValue('');

    onSelect(value);
  };

  return (
    <Select
      mode="multiple"
      className={classNames(s.selector, className)}
      tagRender={tagRender}
      onSelect={handleSelect}
      onDeselect={onDeselect}
      optionLabelProp="label"
      placeholder={placeholder}
      placement="topLeft"
      onSearch={setInputValue}
      searchValue={inputValue}
      filterOption={filterOption}
    >
      {renderOptions()}
    </Select>
  );
};
