import { Col, Row } from 'antd';
import { QueryNameKey } from 'components/Workspaces/collections';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import React from 'react';

import { Column } from '../../collections';
import EditPresetForm from './EditPresetForm';
import NewPresetForm from './NewPresetForm';

interface Props {
  currentColumns: Column[];
  currentPreset: WorkspacePreset;
  onPresetCreate: (name: string, isPublic: boolean, columns: Column[]) => any;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => any;
  onPresetDelete: (preset: ExistingWorkspacePreset) => any;
  queryName: QueryNameKey;
}

const PresetForm: React.FC<Props> = ({
  currentColumns,
  currentPreset,
  onPresetCreate,
  onPresetUpdate,
  onPresetDelete,
  queryName,
}) => (
  <Row>
    <Col sm={24} md={12}>
      {currentPreset.id == null ? (
        <NewPresetForm currentColumns={currentColumns} onPresetCreate={onPresetCreate} queryName={queryName} />
      ) : (
        <EditPresetForm
          currentPreset={currentPreset}
          onPresetUpdate={onPresetUpdate}
          onPresetDelete={onPresetDelete}
          queryName={queryName}
        />
      )}
    </Col>
  </Row>
);

export default PresetForm;
