import { gql } from 'apollo-boost';

export const SEARCH_ITEM_FRAGMENT = gql`
  fragment searchItemFragment on SearchItemType {
    id
    type
    number
    state
    extras
    createdAt
  }
`;

export const ACCOUNT_SEARCH_BA_FRAGMENT = gql`
  fragment accountSearchBaFragment on BillingAccount {
    id
    type
    accountType
    name
    country
    zip
    city
    address
    vatId
    taxId
  }
`;

export const ACCOUNT_SEARCH_DEAL_FRAGMENT = gql`
  fragment accountSearchDealFragment on Deal {
    pipedriveId
    customerOrderCode

    orderConfirmation {
      id
      billingAccount
      deal {
        pipedriveId
      }
    }
  }
`;
