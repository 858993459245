import {
  ContactsOutlined,
  EuroOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  InboxOutlined,
  MessageOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EMessengerChatSourceType, EMessengerEventBusEvents, EMessengerScreenName } from '@xometry/ui';
import { amplitudeLogger } from 'amplitude';
import { Space } from 'antd';
import { AmplitudeAttributeKey, AmplitudeMessengerEvent, MessengerEntryPoint } from 'collections/amplitudeEvents';
import { IProvider } from 'interfaces/graphql';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { routes } from 'routes';
import { useStore } from 'stores/RootStore';
import { ESideOverlayType, IMessengerInitialScreenParams } from 'stores/SideOverlay/SideOverlayStore';
import { useMessengerEventBus } from 'utils/hooks/useMessengerEventBus';

import { SidebarInner, SidebarMenu, SidebarMenuItem, SidebarMenuItemLink, SidebarTitle } from './styled';

const REPORT_ID = '00O7Q0000075p0UUAQ';

interface Props {
  provider: IProvider;
}

const SidebarFC = ({ provider }: Props) => {
  const {
    salesforceId,
    id: partnerId,
    name: partnerName,
    billingAddress: { country },
  } = provider;

  const {
    sideOverlayStore: { context: sideOverlayContext, openSideOverlay },
  } = useStore();
  const { sendEvent } = useMessengerEventBus();

  const handleChatClick: React.MouseEventHandler<HTMLAnchorElement> = () => {
    const messengerContext: IMessengerInitialScreenParams = {
      initialScreen: {
        name: EMessengerScreenName.CHAT,
        params: {
          chatSource: {
            id: String(partnerId),
            title: `${partnerName} — ${country}`,
            sourceType: EMessengerChatSourceType.PARTNER,
            sourceId: String(partnerId),
          },
        },
      },
    };

    if (sideOverlayContext.type === ESideOverlayType.Messenger) {
      sendEvent?.(EMessengerEventBusEvents.REINITIALIZE_MC, messengerContext);
    } else {
      openSideOverlay({
        type: ESideOverlayType.Messenger,
        ...messengerContext,
      });
    }

    amplitudeLogger({
      event: AmplitudeMessengerEvent.MESSENGER_OPENED,
      attrs: {
        [AmplitudeAttributeKey.ENTRY_POINT]: MessengerEntryPoint.SIDEBAR,
        sourceType: EMessengerChatSourceType.PARTNER,
        sourceId: String(partnerId),
      },
    });
  };

  return (
    <SidebarInner>
      <SidebarMenu>
        <SidebarMenuItemLink
          to={`${routes.providerPath(partnerId)}/edit`}
          selected={window.location.pathname.indexOf('edit') >= 0}
        >
          <SidebarTitle className="p-l-xs">
            <UserOutlined />
            Profile
          </SidebarTitle>
        </SidebarMenuItemLink>
        <SidebarMenuItemLink
          to={`${routes.providerPath(partnerId)}/contacts`}
          selected={window.location.pathname.indexOf('contacts') >= 0}
        >
          <SidebarTitle className="p-l-xs">
            <ContactsOutlined />
            Contacts
          </SidebarTitle>
        </SidebarMenuItemLink>

        <SidebarMenuItemLink
          to={routes.providerBalancePath(partnerId)}
          selected={window.location.pathname.indexOf('balance') >= 0}
        >
          <SidebarTitle className="p-l-xs">
            <EuroOutlined />
            Finance
          </SidebarTitle>
        </SidebarMenuItemLink>

        {salesforceId != null && (
          <SidebarMenuItem
            href={routes.external.salesforceReportById(REPORT_ID, salesforceId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SidebarTitle className="p-l-xs">
              <InboxOutlined />
              <Space>
                SF Cases <FontAwesomeIcon icon={faExternalLinkAlt} />
              </Space>
            </SidebarTitle>
          </SidebarMenuItem>
        )}

        <SidebarMenuItem
          href={`${routes.providerPath(partnerId)}/production_orders`}
          selected={window.location.pathname.indexOf('production_orders') >= 0}
        >
          <SidebarTitle className="p-l-xs">
            <FileSearchOutlined />
            Production Orders
          </SidebarTitle>
        </SidebarMenuItem>
        <SidebarMenuItem
          href={`${routes.providerPath(partnerId)}/invoices`}
          selected={window.location.pathname.indexOf('invoices') >= 0}
        >
          <SidebarTitle className="p-l-xs">
            <FileDoneOutlined />
            Invoices
          </SidebarTitle>
        </SidebarMenuItem>
        <SidebarMenuItem
          href={`${routes.providerPath(partnerId)}/job_offers`}
          selected={window.location.pathname.indexOf('job_offers') >= 0}
        >
          <SidebarTitle className="p-l-xs">
            <FileSyncOutlined />
            Job Offers
          </SidebarTitle>
        </SidebarMenuItem>
        <SidebarMenuItem
          href={`${routes.providerPath(partnerId)}/rfq_offers`}
          selected={window.location.pathname.indexOf('rfq_offers') >= 0}
        >
          <SidebarTitle className="p-l-xs">
            <FileSyncOutlined />
            RFQ Offers
          </SidebarTitle>
        </SidebarMenuItem>
        <SidebarMenuItem
          href={`${routes.providerPath(partnerId)}/shippings`}
          selected={window.location.pathname.indexOf('shippings') >= 0}
        >
          <SidebarTitle className="p-l-xs">
            <FileSyncOutlined />
            Shippings
          </SidebarTitle>
        </SidebarMenuItem>
        <SidebarMenuItem
          href={`${routes.providerPath(partnerId)}/rating_by_parts`}
          selected={window.location.pathname.indexOf('rating_by_parts') >= 0}
        >
          <SidebarTitle className="p-l-xs">
            <FileSyncOutlined />
            Rating details
          </SidebarTitle>
        </SidebarMenuItem>
        <SidebarMenuItem onClick={handleChatClick}>
          <SidebarTitle className="p-l-xs">
            <MessageOutlined />
            General chat
          </SidebarTitle>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarInner>
  );
};

export const Sidebar = observer(SidebarFC);
