import { LinkOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Form, Input, Popconfirm, Space, Switch, Tooltip } from 'antd';
import { BankPaymentsActions, ErpPermissionGroup, GroupAndAction } from 'collections/authorization';
import { Notification } from 'components/UI';
import { colors } from 'components/UI/colors';
import { QueryNameKey } from 'components/Workspaces/collections';
import { FormContainer, Notice } from 'components/Workspaces/General/shared/GeneralWorkspace/EditFiltersModal/styled';
import {
  IWorkspacePresetsAssignDefaultPresetResponse,
  IWorkspacePresetsRemoveDefaultPresetResponse,
  WORKSPACE_PRESETS_ASSIGN_DEFAULT_PRESET,
  WORKSPACE_PRESETS_REMOVE_DEFAULT_PRESET,
} from 'components/Workspaces/General/shared/GeneralWorkspace/graphql/mutations';
import {
  GET_DEFAULT_WORKSPACE_PRESET,
  IDefaultWorkspacePresetResponse,
} from 'components/Workspaces/General/shared/GeneralWorkspace/graphql/queries';
import { ExistingWorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { map } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { useStore } from 'stores/RootStore';
import { copyToClipboard } from 'utils/clipboard';
import useCurrentUserPermissions from 'utils/hooks/useCurrentUserPermissions';
import { required } from 'validators/rules';

import s from './EditPresetForm.module.less';
import { IPresetParams } from './interfaces';

const UPDATE_PERMISSION_GROUPS: Record<string, GroupAndAction | undefined> = {
  workspacesBankPayments: {
    group: ErpPermissionGroup.BankPayments,
    action: BankPaymentsActions.BankPaymentListUpdatePresets,
  },
};

interface Props {
  currentPreset: ExistingWorkspacePreset;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => any;
  onPresetDelete: (preset: ExistingWorkspacePreset) => any;
  queryName: QueryNameKey;
}

const EditPresetForm: React.FC<Props> = ({ currentPreset, onPresetUpdate, onPresetDelete, queryName }) => {
  const [form] = Form.useForm<IPresetParams>();

  const { userStore } = useStore();
  const currentUserId = userStore.id;

  const { allPermissions } = useCurrentUserPermissions();

  const { data: defaultPresetData, loading: defaultPresetLoading } = useQuery<IDefaultWorkspacePresetResponse>(
    GET_DEFAULT_WORKSPACE_PRESET,
    {
      variables: { workspaceName: queryName },
    },
  );

  const [assignDefaultPreset, { loading: assignDefaultPresetLoading }] =
    useMutation<IWorkspacePresetsAssignDefaultPresetResponse>(WORKSPACE_PRESETS_ASSIGN_DEFAULT_PRESET, {
      onCompleted: () => Notification.Success('Default preset successfully updated'),
      refetchQueries: [
        {
          query: GET_DEFAULT_WORKSPACE_PRESET,
          variables: { workspaceName: queryName },
        },
      ],
    });

  const [removeDefaultPreset, { loading: removeDefaultPresetLoading }] =
    useMutation<IWorkspacePresetsRemoveDefaultPresetResponse>(WORKSPACE_PRESETS_REMOVE_DEFAULT_PRESET, {
      onCompleted: () => Notification.Success('Default preset successfully removed'),
      refetchQueries: [
        {
          query: GET_DEFAULT_WORKSPACE_PRESET,
          variables: { workspaceName: queryName },
        },
      ],
    });

  const handleMakeDefaultClick = async () => {
    await assignDefaultPreset({
      variables: {
        workspaceName: queryName,
        presetId: currentPreset.id,
      },
    });
  };

  const handleRemoveDefaultClick = async () => {
    await removeDefaultPreset({
      variables: { workspaceName: queryName },
    });
  };

  const handleUpdatePresetClick = () => {
    onPresetUpdate({
      ...currentPreset,
      ...form.getFieldsValue(),
    });
  };

  const handleDeletePresetClick = () => {
    onPresetDelete(currentPreset);
  };

  const handleCopyPresetLinkClick = () => {
    const url = window.location.href.split('?')[0];
    copyToClipboard(url + `?preset=${currentPreset.id}`);
  };

  const currentPresetIsDefault = currentPreset.id === defaultPresetData?.defaultWorkspacePreset?.id;

  let showUpdateButton = false;

  if (allPermissions != null) {
    const workspacesPermissions = map(allPermissions[ErpPermissionGroup.Workspaces], 'name');

    const groupAndAction = UPDATE_PERMISSION_GROUPS[queryName];
    const groupPermissions = groupAndAction != null ? map(allPermissions[groupAndAction.group], 'action') : [];

    showUpdateButton =
      currentPreset.owner.id.toString() === currentUserId ||
      workspacesPermissions.includes('update_all_presets') ||
      groupPermissions.includes(groupAndAction?.action ?? '');
  }

  return (
    <FormContainer>
      <Notice>
        Preset created by {currentPreset.owner.name}
        &nbsp; at {moment(currentPreset.createdAt).format('DD.MM.YYYY HH:mm')}
      </Notice>

      {currentPreset.lastUpdatedBy != null && (
        <Notice>
          Last updated by {currentPreset.lastUpdatedBy.name}
          &nbsp; at {moment(currentPreset.updatedAt).format('DD.MM.YYYY HH:mm')}
        </Notice>
      )}

      <Form className="m-t-sm" form={form} initialValues={currentPreset} layout="horizontal">
        <Space direction="horizontal" className={s.presetNameWrapper}>
          <Form.Item name="name" label="Preset name:" rules={[required(true)]} className={s.presetNameField}>
            <Input disabled />
          </Form.Item>
          <Tooltip title="Copy link to the clipboard">
            <Button type="link" icon={<LinkOutlined />} onClick={handleCopyPresetLinkClick} />
          </Tooltip>
        </Space>

        <Form.Item name="public" label="Visible to everyone" valuePropName="checked">
          <Switch disabled />
        </Form.Item>

        <Space size={16} direction="horizontal">
          {showUpdateButton && (
            <Button htmlType="button" onClick={handleUpdatePresetClick} type="primary">
              Update preset
            </Button>
          )}

          {!defaultPresetLoading &&
            (currentPresetIsDefault ? (
              <Button
                loading={removeDefaultPresetLoading}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleRemoveDefaultClick}
                style={{
                  borderColor: colors.redDarker,
                  color: colors.redDarker,
                  width: 180,
                }}
              >
                Unset default preset
              </Button>
            ) : (
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              <Button loading={assignDefaultPresetLoading} onClick={handleMakeDefaultClick} style={{ width: 180 }}>
                Set preset as default
              </Button>
            ))}

          <Popconfirm onConfirm={handleDeletePresetClick} title="Are you sure you want to delete the preset?">
            {showUpdateButton && (
              <Button type="link" danger>
                Delete preset
              </Button>
            )}
          </Popconfirm>
        </Space>
      </Form>
    </FormContainer>
  );
};

export default EditPresetForm;
