import { Form, Row } from 'antd';
import { QueryNameKey } from 'components/Workspaces/collections';
import {
  Column,
  IDataItem,
  ISortParam,
  ITopFilter,
  WorkspaceGraphQLObjectField,
  WorkspaceGraphQLScalarField,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { FilterItem } from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore';
import { IPresetAttributes } from 'components/Workspaces/General/shared/GeneralWorkspace/withPresets';
import { HeaderDecorator } from 'components/Workspaces/General/shared/interfaces';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { includes, reject } from 'lodash-es';
import React from 'react';

import Filters from './Filters';
import { FiltersContainer } from './styled';
import TopFiltersForm from './TopFiltersForm';

interface Props {
  columns: Column[];
  header: string;
  headerDecorator?: HeaderDecorator;
  hidePresetFilters?: boolean;
  hideUndefinedColumns?: boolean;
  preset: WorkspacePreset;
  presetSelector: React.ReactNode;
  queryName: QueryNameKey;
  scalarFields: WorkspaceGraphQLScalarField[];
  selectedRecords: IDataItem[];
  sortParams: ISortParam[];
  topFilters?: ITopFilter[];
  workspaceObjectFields: WorkspaceGraphQLObjectField[];

  onColumnsUpdate: (columns: Column[]) => void;
  onFiltersUpdate?: (filters: FilterItem) => void;
  onPresetCreate: (attributes: IPresetAttributes) => void;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => void;
  onPresetDelete: (preset: ExistingWorkspacePreset) => void;
  onSortParamsUpdate: (sortParams: ISortParam[]) => any;
}

const FiltersAndActions: React.FC<Props> = ({
  columns,
  header,
  headerDecorator,
  hidePresetFilters,
  hideUndefinedColumns,
  preset,
  presetSelector,
  queryName,
  scalarFields,
  sortParams,
  topFilters,
  workspaceObjectFields,

  onColumnsUpdate,
  onSortParamsUpdate,
  onFiltersUpdate,
  onPresetCreate,
  onPresetUpdate,
  onPresetDelete,
}) => {
  const [topFiltersForm] = Form.useForm();

  const attributes = reject(scalarFields, (field) => includes(field.description, 'custom'));
  const objectAttributes = reject(workspaceObjectFields, (field) => includes(field.description, 'custom'));

  const filtersComponent = (
    <Filters
      attributes={attributes}
      columns={columns}
      hidePresetFilters={hidePresetFilters}
      hideUndefinedColumns={hideUndefinedColumns}
      objectAttributes={objectAttributes}
      preset={preset}
      queryName={queryName}
      sortParams={sortParams}
      onSortParamsUpdate={onSortParamsUpdate}
      onColumnsUpdate={onColumnsUpdate}
      onFiltersUpdate={onFiltersUpdate}
      onPresetCreate={onPresetCreate}
      onPresetUpdate={onPresetUpdate}
      onPresetDelete={onPresetDelete}
    />
  );

  if (headerDecorator != null) {
    return (
      <>
        {headerDecorator({
          filtersComponent,
          presetSelector,
          attributes,
          columns,
          form: topFiltersForm,
          objectAttributes,
          topFilters,
          onFiltersUpdate,
        })}
      </>
    );
  }

  return (
    <FiltersContainer>
      <Row justify="space-between">
        <div className="top-left">
          <h1 className="header">{header}</h1>
          {topFilters != null && (
            <TopFiltersForm
              attributes={attributes}
              columns={columns}
              form={topFiltersForm}
              objectAttributes={objectAttributes}
              topFilters={topFilters}
              onFiltersUpdate={onFiltersUpdate}
            />
          )}
        </div>

        <div className="top-right">
          <label htmlFor="presetSelector" style={{ marginTop: 4 }}>
            Preset:
          </label>
          &nbsp;&nbsp;&nbsp;
          <div>{presetSelector}</div>
          {filtersComponent}
        </div>
      </Row>
    </FiltersContainer>
  );
};

export default FiltersAndActions;
