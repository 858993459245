import { gql } from 'apollo-boost';

import { ERP_PERMISSION_FRAGMENT } from './erpPermissions';

export const USER_PERMISSION_FRAGMENT = gql`
  fragment userPermissionFragment on UserPermission {
    id
    expiresAt
    erpPermission {
      ...erpPermissionFragment
    }
  }
  ${ERP_PERMISSION_FRAGMENT}
`;
