import { EMessengerAppName, PinnedMessagesAppParams, TMessengerAppParams } from '@xometry/ui';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';

// It can be overloaded by adding union
type IProps =
  | {
      name: 'Messenger';
      containerId: string;
      host: string;
      appParams:
        | TMessengerAppParams<EMessengerAppName.ERP_MAIN>
        | TMessengerAppParams<EMessengerAppName.ERP_DOCUMENT_PAGE>;
    }
  | {
      name: 'PinnedMessages';
      containerId: string;
      host: string;
      appParams: PinnedMessagesAppParams;
    };

declare const window: any;

interface TManifest {
  files: Record<string, string>;
}

const ts = new Date().getTime();
const tsParam = `ts=${ts}`;

export const MicroFrontend: FC<IProps> = ({ name, containerId, host, appParams }) => {
  const hasLoadedRef = useRef(false);

  const renderMicroFrontend = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window[`render${name}`]?.(containerId, appParams);
  }, [appParams, containerId, name]);

  const unmountMicroFrontend = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window[`unmount${name}`]?.(containerId);
  }, [containerId, name]);

  const loadMicroFrontend = useMemo(
    () => async (scriptId: string) => {
      const response = await fetch(`${host}/asset-manifest.json?${tsParam}`);
      const manifest = (await response.json()) as TManifest;

      const script = document.createElement('script');
      script.id = scriptId;
      script.crossOrigin = '';

      const scriptSrc = manifest.files['main.js'];
      script.src = scriptSrc.startsWith('http') ? scriptSrc : `${host}${scriptSrc}`;
      script.onload = renderMicroFrontend;

      const CSSFileName = 'main.css';

      if (manifest.files[CSSFileName]) {
        const CSSLink = document.createElement('link');
        CSSLink.type = 'text/css';
        CSSLink.rel = 'stylesheet';
        const CSSLinkHref = manifest.files[CSSFileName];
        CSSLink.href = CSSLinkHref.startsWith('http') ? CSSLinkHref : `${host}${CSSLinkHref}`;

        CSSLink.onload = () => {
          document.head.appendChild(script);
        };

        document.head.appendChild(CSSLink);
      } else {
        document.head.appendChild(script);
      }
    },
    [host, renderMicroFrontend],
  );

  useEffect(() => {
    return () => {
      unmountMicroFrontend();
    };
  }, [unmountMicroFrontend]);

  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`;

    if (!hasLoadedRef.current) {
      loadMicroFrontend(scriptId)
        .then(() => {
          hasLoadedRef.current = true;
        })
        .catch((e: unknown) => {
          console.error(`Downloading of ${name} application has failed.\n`, e);
        });
    }
  }, [loadMicroFrontend, name]);

  return (
    <div
      id={containerId}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
};
