import {
  Money,
  ProviderOrdersSamplesConfirmationStatusEnum,
  ProviderOrdersSamplesProviderOrderTypeEnum,
} from '__generated__/types';
import { ShippingProcessingStatus } from 'collections/shippingConstants';
import { ObjectType } from 'interfaces/shared';
import { IncomingInvoiceState } from 'interfaces/stores/IncomingInvoiceStore';

export enum DealObjectListKeys {
  D = 'deal',
  E = 'enquiry',
  OC = 'orderConfirmation',
  Q = 'quotations',
  P = 'payments',
  J = 'jobs',
  PO = 'providerOrders',
  DL = 'deliveries',
  SH = 'shippings',
  QC = 'qualityControls',
  OI = 'outcomingInvoices',
  II = 'incomingInvoices',
  CL = 'claims',
  PZ = 'payouts',
  SU = 'storages',
  RFQ = 'rfqs',
}

export interface DealObjectList {
  [DealObjectListKeys.D]: {
    pipedriveId: number;
  };
  [DealObjectListKeys.E]?: {
    id: number;
    createdAt: string;
    currency?: string;
    number?: string;
    updatedAt: string;
    state: string;
    canceledAt: string;
    totalAmountNet: number;
    totalAmountBrut: number;
  };
  [DealObjectListKeys.Q]: QuotationObjectListItem[];
  [DealObjectListKeys.P]: PaymentObjectListItem[];
  [DealObjectListKeys.PO]: POObjectListItem[];
  [DealObjectListKeys.DL]: DLObjectListItem[];
  [DealObjectListKeys.QC]: QCObjectListItem[];
  [DealObjectListKeys.SU]: SUObjectListItem[];
  [DealObjectListKeys.SH]: SHObjectListItem[];
  [DealObjectListKeys.CL]: CLObjectListItem[];
  [DealObjectListKeys.PZ]: PZObjectListItem[];
  [DealObjectListKeys.II]: IIObjectListItem[];
  [DealObjectListKeys.OI]: OIObjectListItem[];
  [DealObjectListKeys.J]: JobObjectListItem[];
  [DealObjectListKeys.OC]?: {
    id: number;
    createdAt: string;
    currency?: string;
    number?: string;
    updatedAt: string;
    state: string;
    publishedAt: string;
    totalAmountNet?: number;
    totalAmountBrut?: number;
  };
  [DealObjectListKeys.RFQ]: Array<{
    id: number;
    state: string;
    publicationEnd: string;
  }>;
}

interface PaymentObjectListItem {
  amountBrut?: number;
  amountNet?: number;
  createdAt: string;
  currency?: string;
  id: number;
  isRefund: boolean;
  methodId?: number;
  methodType?: string;
  number?: string;
  paidAt?: string;
  positions: PaymentPositionObjectListItem[];
  sourceId?: number;
  sourceType?: ObjectType;
  state: string;
  transactionType?: string;
  updatedAt: string;
}

export interface PaymentPositionObjectListItem {
  amountNet: number;
  amountBrut: number;
}

interface JobObjectListItem {
  id: number;
  createdAt: string;
  number?: string;
  updatedAt: string;
  state: string;
  publicationEnd?: string;
  value: number;
  valueCurrency?: string;

  providerOrder?: {
    id: number;
  };
}

interface DLObjectListItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  state: string;

  direction: string;
  url: string;
  providerOrder?: {
    id: number;
  };
}

interface QCObjectListItem {
  id: number;
  createdAt: string;
  number?: string;
  updatedAt: string;
  state: string;

  kind: string;
  relatedProviderOrders?: Array<{ id: number; number: string }> | null;
  positions?: Array<{ id: number; techVerdict?: string | null; businessVerdict?: string | null }> | null;
}

interface SHObjectListItem {
  id: number;
  createdAt: string;
  number?: string;
  updatedAt: string;
  state: string;

  direction: string;
  receivedProcessingStatus: ShippingProcessingStatus;
  relatedProviderOrders: {
    id: number;
    number: string;
  }[];
}

interface CLObjectListItem {
  id: number;
  createdAt: string;
  number?: string;
  updatedAt: string;
  state?: string;

  name: string;
}

interface PZObjectListItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  amount?: number;
  amountMoney?: Money;
  state: string;
  sourceId: number;
  sourceType: string;
  paidAt: string;
  isRefund: boolean;

  number: string;
}

export interface OIObjectListItem {
  id: number;
  createdAt: string;
  currency?: string;
  updatedAt: string;
  state: string;

  refInvoice?: {
    id: number;
    number: number;
  };
  paidAt?: string;
  registeredAt?: string;
  invoiceNeedFix?: { by: string; at: string };
  invoiceReady?: { by: string; at: string };
  invoiceChecked?: { by: string; at: string };
  isStorno: boolean;
  creditNote: boolean;
  number: string;
  uiNumber: string;
  totalAmountNetto?: number;
  totalAmountBrutto?: number;
}

interface IIObjectListItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  state: IncomingInvoiceState;

  refInvoice?: {
    id: number;
  };
  providerOrderId?: number;
  paidAt?: string;
  registeredAt?: string;
  validity?: string;
  isPartial: boolean;
  creditNote: boolean;
  number: string;
  totalAmountNetto?: number;
  totalAmountNettoMoney?: Money;
  totalAmountBrutto?: number;
  totalAmountBruttoMoney?: Money;
  selfBilling: boolean;
}

interface SUObjectListItem {
  id: number;
  createdAt: string;
  number?: string;
  updatedAt: string;
  state: string;

  processingState: string;
  storageType: string;
  quantityLeft: number;
  quantityInitial: number;
}

export interface POObjectListItem {
  conformityCheck?: 'ok' | 'has_issues' | 'issues_resolved';
  createdAt: string;
  currency?: string;
  deadline: string;
  fullyPaidAt: string;
  id: number;
  number?: string;
  partsValueNetto?: number;
  servicesValueNetto?: number;
  state: string;
  updatedAt: string;

  samplesMainProviderOrderId?: string;
  samplesPoType?: ProviderOrdersSamplesProviderOrderTypeEnum;
  samplesConfirmationStatus?: ProviderOrdersSamplesConfirmationStatusEnum;
  samplesProviderOrder?: {
    id: number;
    number: string;
    samplesPoType?: ProviderOrdersSamplesProviderOrderTypeEnum;
    samplesConfirmationStatus?: ProviderOrdersSamplesConfirmationStatusEnum;
  };
}

interface QuotationObjectListItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  state: string;
  url: string;
}
