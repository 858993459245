import { TemplateChannelEnum, TemplateLocaleEnum } from '__generated__/types';
import { TagView, UserTagView } from '@xometry/ui';
import { Form, FormInstance, Select, SelectProps, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import clsx from 'clsx';
import { TemplateModalFormFieldEnum, TemplateModalFormValues } from 'components/TemplatesModal/TemplateModal.types';
import { Attachment, TemplatesAttachments } from 'components/TemplatesModal/TemplatesAttachments/TemplatesAttachments';
import { UsersSelect } from 'components/TemplatesModal/UsersSelect/UsersSelect';
import { EmailsField } from 'components/UI/Form/EmailsField';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TemplateFragment } from 'utils/graphql/fragments/__generated__/template';
import { UserFragmentFragment } from 'utils/graphql/fragments/__generated__/userFragment';
import { useRenderedTemplateQuery } from 'utils/graphql/queries/__generated__/renderedTemplate';
import { TemplateModalQuery } from 'utils/graphql/queries/__generated__/templateModal';
import { useUsers } from 'utils/hooks/useUsers';

import s from './TemplatesModalForm.module.less';

interface Props {
  form: FormInstance<TemplateModalFormValues>;
  templateModalData: TemplateModalQuery;
  selectedCategoryId: string | null;
  setSelectedCategoryId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTemplate: TemplateFragment | null;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<TemplateFragment | null>>;
  isVisibleToExternals: boolean;
  isMobile: boolean;
}

const renderEmailTag: SelectProps['tagRender'] = ({ onClose, value }) => (
  <TagView onCrossClick={onClose}>{value}</TagView>
);

export const TemplatesModalForm: FC<Props> = ({
  form,
  templateModalData,
  selectedCategoryId,
  setSelectedCategoryId,
  selectedTemplate,
  setSelectedTemplate,
  isVisibleToExternals,
  isMobile,
}) => {
  const [emailFieldsVisible, setEmailFieldsVisible] = useState(false);

  const { getUserById } = useUsers();

  const { data: renderedTemplateData, loading: renderedTemplateLoading } = useRenderedTemplateQuery({
    variables: {
      id: selectedTemplate?.id as string,
      sourceId: templateModalData.messengerSource.sourceId,
      sourceType: templateModalData.messengerSource.sourceType,
    },
    skip: !selectedTemplate,
    onCompleted: (data) => {
      if (!data.messengerRenderTemplate) {
        return;
      }

      const { content, recipients = [], carbonCopy = [], blindCarbonCopy = [] } = data.messengerRenderTemplate;

      form.setFieldValue(TemplateModalFormFieldEnum.To, recipients);
      form.setFieldValue(TemplateModalFormFieldEnum.Cc, carbonCopy);
      form.setFieldValue(TemplateModalFormFieldEnum.Bcc, blindCarbonCopy);
      form.setFieldValue(TemplateModalFormFieldEnum.Content, content);
    },
  });

  const handleTemplateSelect = useCallback(
    (template: TemplateFragment | undefined) => {
      form.setFieldValue(TemplateModalFormFieldEnum.To, []);
      form.setFieldValue(TemplateModalFormFieldEnum.Cc, []);
      form.setFieldValue(TemplateModalFormFieldEnum.Bcc, []);
      form.setFieldValue(TemplateModalFormFieldEnum.Content, '');

      setSelectedTemplate(template || null);
    },
    [form, setSelectedTemplate],
  );

  const { category, isEmailSendingAvailable, isUploadingFilesAvailable } = useMemo(() => {
    const category = templateModalData.messengerTemplateCategories.find(({ id }) => selectedCategoryId === id);

    return {
      category,
      isEmailSendingAvailable: category?.channels.includes(TemplateChannelEnum.Email),
      isUploadingFilesAvailable: !category?.channels.includes(TemplateChannelEnum.Whatsapp),
    };
  }, [selectedCategoryId, templateModalData?.messengerTemplateCategories]);

  const templateMapByConstantName = useMemo(() => {
    const templateMapByConstantName: Record<string, Partial<Record<TemplateLocaleEnum, TemplateFragment>>> = {};

    category?.templates.forEach((template) => {
      const { constantName, locale } = template;

      if (!constantName) return;

      if (!templateMapByConstantName[constantName]) {
        templateMapByConstantName[constantName] = {
          [locale]: template,
        };
      } else {
        templateMapByConstantName[constantName][locale] = template;
      }
    });

    return templateMapByConstantName;
  }, [category?.templates]);

  /**
   * Handles the changing of a category.
   */
  const handleCategoryChange = useCallback(
    (categoryId: string) => {
      setSelectedCategoryId(categoryId);
    },
    [setSelectedCategoryId],
  );

  /**
   * Handles the setting of a template's constant name.
   */
  const handleTemplateConstantNameChange = useCallback(
    (newTemplateConstantName: string) => {
      form.setFieldValue(TemplateModalFormFieldEnum.TemplateConstantName, newTemplateConstantName);
      const localesOfTemplate = templateMapByConstantName[newTemplateConstantName];

      if (localesOfTemplate) {
        const currentLocale = form.getFieldValue(TemplateModalFormFieldEnum.Locale) as TemplateLocaleEnum;

        form.setFieldValue(
          TemplateModalFormFieldEnum.Locale,
          localesOfTemplate[currentLocale] ? currentLocale : Object.keys(localesOfTemplate)[0],
        );

        const newTemplate =
          templateMapByConstantName[form.getFieldValue(TemplateModalFormFieldEnum.TemplateConstantName) as string][
            form.getFieldValue(TemplateModalFormFieldEnum.Locale) as TemplateLocaleEnum
          ];

        handleTemplateSelect(newTemplate);
      }
    },
    [form, handleTemplateSelect, templateMapByConstantName],
  );

  const handleLocaleChange = useCallback(
    (locale: string) => {
      const templateConstantName = form.getFieldValue(TemplateModalFormFieldEnum.TemplateConstantName) as string;
      const newTemplate = templateMapByConstantName[templateConstantName]?.[locale as TemplateLocaleEnum];

      handleTemplateSelect(newTemplate);
    },
    [form, handleTemplateSelect, templateMapByConstantName],
  );

  /**
   * Setting of the first template from new category.
   */
  useEffect(() => {
    const firstConstantName = category?.templates[0]?.constantName;

    if (firstConstantName) {
      handleTemplateConstantNameChange(firstConstantName);
    }
  }, [category?.templates, handleTemplateConstantNameChange]);

  const handleMentionSelect = useCallback(
    (userId: UserFragmentFragment['id']) => {
      const curMentions = form.getFieldValue(TemplateModalFormFieldEnum.Mentions) as string[] | undefined;
      const newMentions = [...(curMentions || []), String(userId)];

      form.setFieldValue(TemplateModalFormFieldEnum.Mentions, newMentions);
    },
    [form],
  );

  const handleMentionDeselect = useCallback(
    (userId: number) => {
      const curMentions = form.getFieldValue(TemplateModalFormFieldEnum.Mentions) as string[];
      const newMentions = curMentions.filter((id) => id !== String(userId));

      form.setFieldValue(TemplateModalFormFieldEnum.Mentions, newMentions);
    },
    [form],
  );

  const handleToChange = (value: string[]) => {
    form.setFieldValue(TemplateModalFormFieldEnum.To, value);
  };

  const handleCcChange = (value: string[]) => {
    form.setFieldValue(TemplateModalFormFieldEnum.Cc, value);
  };

  const handleBccChange = (value: string[]) => {
    form.setFieldValue(TemplateModalFormFieldEnum.Bcc, value);
  };

  /**
   * Initial setting up of the first category.
   */
  useEffect(() => {
    if (templateModalData) {
      const newCategoryId = templateModalData.messengerTemplateCategories[0]?.id;

      form.setFieldValue(TemplateModalFormFieldEnum.Category, newCategoryId);
      handleCategoryChange(newCategoryId);
    }
  }, [form, handleCategoryChange, templateModalData]);

  const mods = { [s.isMobile]: isMobile };

  return (
    <Form form={form} className={clsx(s.form, { ...mods })}>
      <div className={clsx(s.topRow, { ...mods })}>
        <Form.Item
          name={TemplateModalFormFieldEnum.Category}
          label="Template category:"
          className={clsx(s.verticalFormItem, { ...mods })}
        >
          <Select
            placeholder="Template category"
            onChange={handleCategoryChange}
            getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
            options={templateModalData?.messengerTemplateCategories.map(({ id, title }) => ({
              value: id,
              label: title,
            }))}
          />
        </Form.Item>
        <Form.Item
          name={TemplateModalFormFieldEnum.TemplateConstantName}
          label="Template name:"
          className={clsx(s.verticalFormItem, { ...mods })}
          shouldUpdate
        >
          <Select
            placeholder="Template name"
            onChange={handleTemplateConstantNameChange}
            getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
            options={Object.entries(templateMapByConstantName).map(([templateConstantName, template]) => ({
              value: templateConstantName,
              label: (
                template[form.getFieldValue(TemplateModalFormFieldEnum.Locale) as TemplateLocaleEnum] ||
                Object.values(template)[0]
              )?.name,
            }))}
          />
        </Form.Item>
        <Form.Item
          name={TemplateModalFormFieldEnum.Locale}
          label="Locale:"
          className={clsx(s.verticalFormItem, s.locale, { ...mods })}
          shouldUpdate
        >
          <Select
            placeholder="Locale"
            getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
            options={Object.keys(
              templateMapByConstantName[form.getFieldValue('templateConstantName') as string] || {},
            ).map((locale) => ({ value: locale, label: locale.toLocaleUpperCase() }))}
            onChange={handleLocaleChange}
          />
        </Form.Item>
      </div>
      <div className={s.selects}>
        <div className={clsx(s.selectRow, { ...mods })}>
          <Form.Item label="Subscribers:">
            <div className={clsx(s.subscribersList, { ...mods })}>
              {templateModalData.messengerSource.followingUserIds.map((userId) => {
                const user = getUserById(userId);

                if (!user) {
                  return null;
                }

                return (
                  <UserTagView
                    key={userId}
                    className={s.userTag}
                    name={user.name}
                    imageUrl={user.imageUrl || ''}
                    size="small"
                  />
                );
              })}
            </div>
          </Form.Item>
        </div>
        {isVisibleToExternals ? null : (
          <div className={clsx(s.selectRow, { ...mods })}>
            <Form.Item name={TemplateModalFormFieldEnum.Mentions} label="Mention:">
              <UsersSelect
                onSelect={handleMentionSelect}
                onDeselect={handleMentionDeselect}
                placeholder="Select users to mention"
              />
            </Form.Item>
          </div>
        )}
        {isEmailSendingAvailable ? (
          <>
            <div className={clsx({ [s.selectRow]: true, [s.hidden]: !emailFieldsVisible, ...mods })}>
              <Form.Item name={TemplateModalFormFieldEnum.To} label="To:">
                <EmailsField
                  disabled={renderedTemplateLoading}
                  className={s.emailsSelector}
                  tagRender={renderEmailTag}
                  onChange={handleToChange}
                />
              </Form.Item>
            </div>
            <div className={clsx({ [s.selectRow]: true, [s.hidden]: !emailFieldsVisible, ...mods })}>
              <Form.Item name={TemplateModalFormFieldEnum.Cc} label="CC:">
                <EmailsField
                  disabled={renderedTemplateLoading}
                  className={s.emailsSelector}
                  tagRender={renderEmailTag}
                  onChange={handleCcChange}
                />
              </Form.Item>
            </div>
            <div className={clsx({ [s.selectRow]: true, [s.hidden]: !emailFieldsVisible, ...mods })}>
              <Form.Item name={TemplateModalFormFieldEnum.Bcc} label="BCC:">
                <EmailsField
                  disabled={renderedTemplateLoading}
                  className={s.emailsSelector}
                  tagRender={renderEmailTag}
                  onChange={handleBccChange}
                />
              </Form.Item>
            </div>
          </>
        ) : null}
      </div>
      {isEmailSendingAvailable ? (
        <div className={s.switcherRow}>
          <Switch
            title="Enable additional fields"
            checked={emailFieldsVisible}
            onChange={(checked) => {
              setEmailFieldsVisible(checked);
            }}
            size="small"
          />
          Enable additional fields
        </div>
      ) : null}

      {isUploadingFilesAvailable ? (
        <TemplatesAttachments
          initialAttachments={(renderedTemplateData?.messengerRenderTemplate?.attachments as Attachment[]) || []}
          form={form}
          readOnly={Boolean(renderedTemplateData?.messengerRenderTemplate?.readOnly)}
          isMobile={isMobile}
        />
      ) : null}

      <Form.Item name={TemplateModalFormFieldEnum.Content}>
        <TextArea
          disabled={renderedTemplateLoading}
          className={clsx(s.textarea, { ...mods })}
          readOnly={renderedTemplateData?.messengerRenderTemplate?.readOnly}
        />
      </Form.Item>
    </Form>
  );
};
