import { gql } from 'apollo-boost';

import { ERP_PERMISSION_FRAGMENT } from './erpPermissions';

export const ROLE_FRAGMENT = gql`
  fragment roleFragment on Role {
    id
    name
    label
    erpPermissions {
      ...erpPermissionFragment
    }
  }
  ${ERP_PERMISSION_FRAGMENT}
`;
