import styled from '@emotion/styled';
import { colors } from 'components/UI/colors';

export const FormContainer = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 10px;
`;

export const Notice = styled.div`
  color: ${colors.gray7};
  font-style: italic;
`;
