import { Button, Select, Space } from 'antd';
import { useAccessErrorNotification } from 'components/shared/AccessError/useAccessErrorNotification';
import { PageLoader } from 'components/UI';
import { Modal, ModalTitle } from 'components/UI/Modal/styled';
import { ExportColumnsMap, QueryNameKey } from 'components/Workspaces/collections';
import { Column, IDataItem, ISortParam } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { useFilters } from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore';
import { generateExportColumns } from 'components/Workspaces/General/shared/GeneralWorkspace/generateExportColumns';
import Columns from 'components/Workspaces/General/shared/GeneralWorkspace/shared/Columns';
import { useFiltersEditPermission } from 'components/Workspaces/General/shared/GeneralWorkspace/useFiltersEditPermission';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { filter, last, map, size } from 'lodash-es';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useExternalDownload } from 'utils/hooks/useDownload';

import { Notice, SelectCountRow } from './styled';
import useLazyExport from './useLazyExport';

const countOptions = [500, 1000, 2500, 5000];

const formatMap: Record<string, string> = {
  csv: 'csv',
  xlsx: 'xlsx',
};

interface Props {
  format: string;
  columns: Column[];
  exportColumns?: ExportColumnsMap;
  onCancel: () => void;
  queryName: QueryNameKey;
  queryOptions?: Record<string, unknown>;
  preset?: WorkspacePreset;
  selectedRecords: IDataItem[];
  sortParams: ISortParam[];
  timezoneOffset: number;
  title: string;
  total: number;
  visible?: boolean;

  onPresetUpdate: (preset: ExistingWorkspacePreset) => any;
}

export const ExportModal: React.FC<Props> = ({
  format,
  columns,
  exportColumns,
  onCancel,
  queryName,
  queryOptions,
  preset,
  selectedRecords,
  sortParams,
  timezoneOffset,
  title,
  total,
  visible,
  onPresetUpdate,
}) => {
  const accessErrorNotification = useAccessErrorNotification();
  const { pathname } = useLocation();
  const urlDownload = useExternalDownload();
  const { isFiltersChangingAllowed, permissionErrorMessage, permissionsGroup } = useFiltersEditPermission({ pathname });

  let initialColumns = generateExportColumns({ columns, extraColumns: exportColumns });

  if (preset?.id != null && preset.exportColumns != null) {
    initialColumns = initialColumns.map((column) => ({
      ...column,
      enabled: preset.exportColumns?.includes(column.key) ?? false,
    }));
  }

  const [currentColumns, setCurrentColumns] = useState<Column[]>(initialColumns);

  const selectCountOptions: { label: string; value: 'only_selected' | number }[] = map(
    filter(countOptions, (x) => x < total),
    (x) => ({
      label: x.toString(),
      value: x,
    }),
  );

  if (total < (last(countOptions) ?? 0)) {
    selectCountOptions.push({
      label: `All ${total}`,
      value: total,
    });
  }

  if (size(selectedRecords) > 0) {
    selectCountOptions.push({
      label: `Only selected (${size(selectedRecords)})`,
      value: 'only_selected',
    });
  }

  const [count, setCount] = useState<number | 'only_selected'>(last(selectCountOptions)?.value ?? 0);

  const filters = useFilters();

  const [loadFile, fileLoading] = useLazyExport({
    queryName: queryName,
    onCompleted: (url: string) => {
      urlDownload(url, `${title}.${formatMap[format]}`);
    },
  });

  const handleCheckAllClick = () => {
    if (!permissionsGroup || isFiltersChangingAllowed) {
      setCurrentColumns(map(currentColumns, (col) => ({ ...col, enabled: true })));
    } else {
      accessErrorNotification(permissionErrorMessage);
    }
  };

  const paginationParams = {
    page: 1,
    pageSize: count === 'only_selected' ? total : count,
  };

  const selectedRecordKeys = count === 'only_selected' ? map(selectedRecords, 'key') : [];

  const handleModalSubmit = async () => {
    await loadFile({
      filename: title,
      format,
      timezoneOffset,
      sortParams,
      paginationParams,
      selectedRecords: selectedRecordKeys,
      filterQuery: filters.toQuery,
      queryOptions,
      columns: map(filter(currentColumns, { enabled: true }), ({ key, title }) => ({ key, title })),
    });
  };

  const handleSaveColumnsClick = () => {
    if (preset?.id == null) return;

    const updatedPreset = {
      ...preset,
      exportColumns: map(filter(currentColumns, { enabled: true }), 'key'),
    };

    onPresetUpdate(updatedPreset);
  };

  if (fileLoading) {
    return <PageLoader title={`Exporting data to ${format.toUpperCase()}...`} isVisible />;
  }

  return (
    <Modal
      destroyOnClose
      okText={`Export ${format.toUpperCase()}`}
      onCancel={onCancel}
      onOk={() => void handleModalSubmit()}
      title={
        <ModalTitle>
          Export to&nbsp;
          {format.toUpperCase()}
        </ModalTitle>
      }
      open={visible}
      width={1090}
    >
      <SelectCountRow justify="space-between">
        <div>
          <label htmlFor="countOptionsSelect">Records count to export:</label>

          <Select
            onChange={setCount}
            options={selectCountOptions}
            size="small"
            style={{ width: 200 }}
            value={count}
            id="countOptionsSelect"
          />
        </div>

        <Space direction="horizontal">
          {preset?.id != null && (
            <Button onClick={handleSaveColumnsClick}>Save selected columns to current preset</Button>
          )}

          <Button onClick={handleCheckAllClick} type="primary">
            Check all columns
          </Button>
        </Space>
      </SelectCountRow>

      <Columns columns={currentColumns} onColumnsChange={setCurrentColumns} span={4} />

      <Notice>Records will be exported with current filters and sorting applied</Notice>
    </Modal>
  );
};
