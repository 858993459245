import { gql } from 'apollo-boost';
import { ExistingWorkspacePreset } from 'interfaces/graphql/workspacePreset';

import { WORKSPACE_PRESET_FRAGMENT } from './fragments';

export interface IWorkspacePresetsCreateResponse {
  workspacePresetsCreate: {
    workspacePreset: ExistingWorkspacePreset;
  };
}

export const WORKSPACE_PRESETS_CREATE = gql`
  mutation workspacePresetsCreate($attributes: OmsInputsWorkspacePresetType!) {
    workspacePresetsCreate(input: { attributes: $attributes }) {
      workspacePreset {
        ...workspacePresetFragment
      }
    }
  }
  ${WORKSPACE_PRESET_FRAGMENT}
`;

export interface IWorkspacePresetsUpdateResponse {
  workspacePresetsUpdate: {
    workspacePreset: ExistingWorkspacePreset;
  };
}

export const WORKSPACE_PRESETS_UPDATE = gql`
  mutation workspacePresetsUpdate(
    $id: Int!
    $query: String
    $columns: [String!]
    $exportColumns: [String!]
    $sortColumn: String
    $sortDirection: String
  ) {
    workspacePresetsUpdate(
      input: {
        id: $id
        query: $query
        columns: $columns
        exportColumns: $exportColumns
        sortColumn: $sortColumn
        sortDirection: $sortDirection
      }
    ) {
      workspacePreset {
        ...workspacePresetFragment
      }
    }
  }
  ${WORKSPACE_PRESET_FRAGMENT}
`;

export interface IWorkspacePresetsDeleteResponse {
  workspacePresetsDelete: {
    success: boolean;
  };
}

export const WORKSPACE_PRESETS_DELETE = gql`
  mutation workspacePresetsDelete($id: ID!) {
    workspacePresetsDelete(input: { id: $id }) {
      success
    }
  }
`;

export interface IWorkspacePresetsAssignDefaultPresetResponse {
  workspacePresetsAssignDefaultPreset: {
    success: boolean;
  };
}

export const WORKSPACE_PRESETS_ASSIGN_DEFAULT_PRESET = gql`
  mutation workspacePresetsAssignDefaultPreset($workspaceName: String!, $presetId: ID!) {
    workspacePresetsAssignDefaultPreset(input: { workspaceName: $workspaceName, presetId: $presetId }) {
      success
    }
  }
`;

export interface IWorkspacePresetsRemoveDefaultPresetResponse {
  workspacePresetsRemoveDefaultPreset: {
    success: boolean;
  };
}

export const WORKSPACE_PRESETS_REMOVE_DEFAULT_PRESET = gql`
  mutation workspacePresetsRemoveDefaultPreset($workspaceName: String!) {
    workspacePresetsRemoveDefaultPreset(input: { workspaceName: $workspaceName }) {
      success
    }
  }
`;
