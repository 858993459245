import { SortDirectionEnum } from '__generated__/types';
import { PageLoader } from 'components/UI';
import { QueryName } from 'components/Workspaces/collections';
import GeneralWorkspace from 'components/Workspaces/General/shared/GeneralWorkspace';
import React, { FC } from 'react';

import { useColumns } from './useColumns';

interface RfqOffersProps {
  initialFilter: string;
}

/** @deprecated import the page from 'src/pages' */
export const RfqOffers: FC<RfqOffersProps> = ({ initialFilter }) => {
  const { loading, columns } = useColumns();

  if (loading) {
    return <PageLoader title="Loading..." isVisible />;
  }

  return (
    <GeneralWorkspace
      header="Local RFQ Offers"
      selectiveFieldsEnabled
      queryName={QueryName.RfqOffers}
      columns={columns}
      initialFilter={initialFilter}
      sortParams={[
        {
          sortBy: 'response_state_sorted',
          direction: SortDirectionEnum.Asc,
        },
      ]}
    />
  );
};
