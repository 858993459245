import { gql } from 'apollo-boost';

export const ENQUIRY_PROMOTION_FRAGMENT = gql`
  fragment enquiryPromotionFragment on OmsEnquiryPromotionType {
    id
    name
    code
    discountUnit
    discountAmount
    settings
    internalComment
    createdAt
    user {
      id
      name
    }
  }
`;

export const ENQUIRY_FRAGMENT = gql`
  fragment enquiryFragment on Enquiry {
    id
    dealId
    name
    state
    certificates
  }
`;
