/* eslint react/jsx-props-no-spreading: "off", react/destructuring-assignment: "off" */
import { MinusOutlined } from '@ant-design/icons';
import { Button, Select, Space } from 'antd';
import { FilterSettings } from 'components/Workspaces/collections';
import {
  Column,
  FilterNextOperator,
  FilterPatternName,
  filterPatterns,
  filterPatternsByType,
  GraphQLScalarType,
  NativeColumn,
  WorkspaceGraphQLField,
  WorkspaceGraphQLObjectField,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { FilterItem } from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore';
import { columnFilterable } from 'components/Workspaces/General/shared/GeneralWorkspace/utils';
import humanizeString from 'humanize-string';
import { compact, concat, filter as _filter, find, flatMap, map, sortBy } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { unwrapFilterValues, wrapFilterValues } from './prepareFilterValues';
import { FilterRowContainer, FilterRowItem } from './styled';
import ValueInputs from './ValueInputs';

interface Props {
  attributes: WorkspaceGraphQLField[];
  objectAttributes: WorkspaceGraphQLObjectField[];
  columns: Column[];
  customFilters?: FilterSettings[];
  hideUndefinedColumns?: boolean;
  node: FilterItem;
  operator?: FilterNextOperator;
  readonly?: boolean;
  remove?: () => any;
}

const FilterRow = observer<Props>(
  ({
    attributes,
    objectAttributes,
    columns,
    customFilters,
    hideUndefinedColumns,
    node,
    operator,
    readonly,
    remove,
  }) => {
    if (node.value.kind === 'group') return null;

    const { filter } = node.value;

    const isCustom = filter.attribute?.startsWith('_custom_');
    const attrName = isCustom ? filter.attribute?.slice(8) : filter.attribute;

    const column = useMemo(() => find(columns, { key: filter.attribute }), [columns, filter.attribute]);

    const handleValuesChange = (values: any[]) => {
      const preparedValues = isCustom ? values : wrapFilterValues({ column, values });

      node.setFilter({ ...filter, values: preparedValues });
    };

    let attributesOptions = sortBy(
      compact(
        map(attributes, (attr) => {
          const col = find(columns, { key: attr.name });

          if (!columnFilterable(col?.settings?.visibility)) return null;

          if (hideUndefinedColumns && col == null) return null;

          const label = col?.settings?.title ?? humanizeString(attr.name);

          return { label, value: attr.name };
        }),
      ),
      'label',
    );

    attributesOptions = concat(
      attributesOptions,
      sortBy(
        compact(
          flatMap(_filter(objectAttributes, { list: false }), (attr) =>
            map(attr.type.fields, (field) => {
              const name = `${attr.name}__${field.name}`;
              const col = find(columns, { key: name });

              if (!columnFilterable(col?.settings?.visibility)) return null;

              const label = col?.settings?.title ?? `${humanizeString(attr.name)}: ${humanizeString(field.name ?? '')}`;

              return { label, value: name };
            }),
          ),
        ),
        'label',
      ),
    );

    attributesOptions = concat(
      map(customFilters, (filter) => ({ label: filter.label, value: `_custom_${filter.attribute}` })),
      attributesOptions,
    );

    const attribute = attrName?.includes('__')
      ? find(
          flatMap(objectAttributes, (objectAttribute) =>
            map(objectAttribute.type.fields, (field) => ({ ...field, name: `${objectAttribute.name}__${field.name}` })),
          ),
          { name: attrName },
        )
      : find(attributes, (x) => x.name === attrName);

    let attributeType = attribute?.type?.name as GraphQLScalarType;

    if (attributeType == null) attributeType = GraphQLScalarType.String;

    let patternOptions;

    if (isCustom) {
      const customFilter = find(customFilters, { attribute: attrName });
      patternOptions = map(customFilter?.filters, (f) => filterPatterns[f.pattern]);
    } else if (attribute?.list) {
      patternOptions = [filterPatterns[FilterPatternName.Contains], filterPatterns[FilterPatternName.Excludes]];
    } else if (attribute?.enum) {
      patternOptions = filterPatternsByType[GraphQLScalarType.String];
    } else {
      patternOptions = filterPatternsByType[attributeType];
    }

    let valueOptions;
    let multipleOptions;

    if (isCustom) {
      const customFilter = find(customFilters, { attribute: attrName });
      const selectedFilter = find(customFilter?.filters, (f) => f.pattern === filter.pattern);
      valueOptions = selectedFilter?.options;
    } else {
      const column = find(columns, { key: filter.attribute }) as NativeColumn;
      valueOptions = column?.settings?.options;
      multipleOptions = !column?.settings?.synthetic && column?.settings?.multipleOptions;
    }

    return (
      <FilterRowContainer root={node.root}>
        <FilterRowItem>
          <Select
            allowClear
            disabled={readonly}
            onChange={(value) => node.setFilter({ attribute: value, values: [] })}
            optionFilterProp="label"
            options={attributesOptions}
            showSearch
            style={{ width: 300 }}
            value={filter.attribute}
          />
        </FilterRowItem>

        <FilterRowItem>
          <Select
            disabled={readonly}
            onChange={(value) => node.setFilter({ ...filter, pattern: value, values: [] })}
            options={patternOptions}
            style={{ width: 200 }}
            value={filter.pattern}
          />
        </FilterRowItem>

        <FilterRowItem>
          {filter.attribute != null && filter.pattern != null && (
            <ValueInputs
              onChange={handleValuesChange}
              options={valueOptions}
              multipleOptions={multipleOptions}
              pattern={filter.pattern}
              readonly={readonly}
              type={attributeType}
              values={unwrapFilterValues({ column, values: filter.values })}
            />
          )}
        </FilterRowItem>

        <FilterRowItem>
          <Space direction="horizontal" size={10}>
            {operator === 'AND' ? (
              <Button disabled={readonly} size="small" type="link" onClick={() => node.addChild('AND')}>
                AND
              </Button>
            ) : (
              <Button disabled={readonly} size="small" type="link" onClick={() => node.addChild('OR')}>
                OR
              </Button>
            )}

            {remove != null && (
              <Button
                disabled={readonly}
                icon={<MinusOutlined />}
                shape="circle"
                size="small"
                type="default"
                onClick={remove}
              />
            )}
          </Space>
        </FilterRowItem>
      </FilterRowContainer>
    );
  },
);

export default FilterRow;
