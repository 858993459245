import { FilterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { BankPaymentsActions, ErpPermissionGroup, GroupAndAction } from 'collections/authorization';
import AuthorizeVisibility from 'components/shared/AuthorizeVisibility';
import { QueryName, QueryNameKey } from 'components/Workspaces/collections';
import {
  Column,
  ISortParam,
  WorkspaceGraphQLField,
  WorkspaceGraphQLObjectField,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import EditFiltersModal from 'components/Workspaces/General/shared/GeneralWorkspace/EditFiltersModal';
import { FilterItem, useFilters } from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore';
import { IPresetAttributes } from 'components/Workspaces/General/shared/GeneralWorkspace/withPresets';
import { camelize } from 'humps';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { filter, map } from 'lodash-es';
import React, { useMemo } from 'react';
import useModal from 'utils/hooks/useModal';

import s from './Filters.module.less';

const editFiltersPermissions: Record<string, GroupAndAction> = {
  [QueryName.BP]: {
    group: ErpPermissionGroup.BankPayments,
    action: BankPaymentsActions.BankPaymentListFilter,
  },
};

interface Props {
  attributes: WorkspaceGraphQLField[];
  columns: Column[];
  hidePresetFilters?: boolean;
  hideUndefinedColumns?: boolean;
  objectAttributes: WorkspaceGraphQLObjectField[];
  preset: WorkspacePreset;
  queryName: QueryNameKey;
  sortParams?: ISortParam[];

  onColumnsUpdate: (columns: Column[]) => void;
  onFiltersUpdate?: (filters: FilterItem) => void;
  onPresetCreate: (attributes: IPresetAttributes) => void;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => void;
  onPresetDelete: (preset: ExistingWorkspacePreset) => void;
  onSortParamsUpdate: (sortParams: ISortParam[]) => void;
}

const Filters: React.FC<Props> = ({
  attributes,
  columns,
  hidePresetFilters,
  hideUndefinedColumns,
  objectAttributes,
  preset,
  queryName,
  sortParams,

  onColumnsUpdate,
  onSortParamsUpdate,
  onFiltersUpdate,
  onPresetCreate,
  onPresetUpdate,
  onPresetDelete,
}) => {
  const filters = useFilters();

  const { modalVisible: filtersModalVisible, openModal: openFiltersModal, closeModal: closeFiltersModal } = useModal();

  const handleFiltersModalSubmit = (columns: Column[], sort?: ISortParam) => {
    onColumnsUpdate(columns);

    onSortParamsUpdate(sort ? [sort] : []);

    closeFiltersModal();

    if (onFiltersUpdate != null) onFiltersUpdate(filters);
  };

  const handlePresetCreate = (name: string, isPublic: boolean, columns: Column[], sort?: ISortParam) => {
    onPresetCreate({
      name,
      isPublic,
      columns: map(filter(columns, { enabled: true }), 'key'),
      query: filters.toQuery,
      sort,
    });

    if (sort) {
      onSortParamsUpdate([sort]);
    }

    closeFiltersModal();
  };

  const handlePresetUpdate = (preset: ExistingWorkspacePreset) => {
    // Update preset.
    onPresetUpdate(preset);

    const sort =
      preset.sortColumn && preset.sortDirection
        ? { sortBy: preset.sortColumn, direction: preset.sortDirection }
        : undefined;

    // Update filters.
    onSortParamsUpdate(sort ? [sort] : []);

    closeFiltersModal();
  };

  const handlePresetDelete = (preset: ExistingWorkspacePreset) => {
    onPresetDelete(preset);
    closeFiltersModal();
  };

  const editFiltersGroupAndAction = editFiltersPermissions[queryName];

  const sort: ISortParam | undefined = useMemo(() => {
    const sortBy = sortParams?.[0]?.sortBy;
    const direction = sortParams?.[0]?.direction;

    if (sortBy && direction) {
      return {
        sortBy: camelize(sortBy),
        direction: direction,
      };
    }

    return undefined;
  }, [sortParams]);

  const editPresetButton = (
    <Button type="primary" className={s.filterButton} icon={<FilterOutlined />} onClick={openFiltersModal} />
  );

  return (
    <>
      {editFiltersGroupAndAction != null ? (
        <AuthorizeVisibility {...editFiltersGroupAndAction}>{editPresetButton}</AuthorizeVisibility>
      ) : (
        editPresetButton
      )}

      {filtersModalVisible && (
        <EditFiltersModal
          attributes={attributes}
          columns={columns}
          currentPreset={preset}
          hidePresetFilters={hidePresetFilters}
          hideUndefinedColumns={hideUndefinedColumns}
          objectAttributes={objectAttributes}
          queryName={queryName}
          defaultSort={sort}
          visible
          onCancel={closeFiltersModal}
          onPresetCreate={handlePresetCreate}
          onPresetUpdate={handlePresetUpdate}
          onPresetDelete={handlePresetDelete}
          onSubmit={handleFiltersModalSubmit}
        />
      )}
    </>
  );
};

export default Filters;
