import styled from '@emotion/styled';
import { colors } from 'components/UI/colors';

export const AttributesFiltersContainer = styled.div`
  border 1px solid ${colors.gray2};
  margin-top: 16px;
  padding: 10px;
  width: 100%;

  > div:not(:first-of-type) {
    margin-top: 15px;
  }

  > input {
    margin-top: 5px;
  }

  h3 {
    margin-bottom: 2px;
    font-weight: 600;

    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }

  button.expand-preset {
    margin-bottom: 5px;
    padding-left: 4px;
  }
`;

export const FilterNodeContainer = styled.div`
  margin: 0;
  padding: 10px 0 10px 0;
  position: relative;

  .border {
    border-bottom: 1px solid ${colors.gray4};
    border-left: 1px solid ${colors.gray4};
    border-top: 1px solid ${colors.gray4};
    height: 100%;
    position: absolute;
    top: 0;
    width: 10px;
  }

  .buttons {
    margin-left: 10px;
    margin-top: 10px;

    button {
      margin-right: 10px;
    }
  }

  .content {
    margin: 0 0 0 10px;
  }

  .value {
    font-weight: 600;
    margin-bottom: 4px;
    margin-left: 0;
    margin-top: 4px;
  }
`;

interface FilterRowContainerProps {
  root?: boolean;
}

export const FilterRowContainer = styled.div<FilterRowContainerProps>`
  align-items: center;
  display: flex;
  justify-content: start;
  margin-left: ${({ root }) => (root ? '10px' : 0)};
`;

export const FilterRowItem = styled.div`
  &:not(:first-of-type) {
    margin-left: 10px;
  }
`;

export const ValueInputsContainer = styled.div`
  display: flex;
  justify-content: start;

  > div:not(:first-of-type),
  input:not(:first-of-type) {
    margin-left: 15px;
  }
`;
